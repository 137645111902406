import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: "root"
})
export class ApiService {

  constructor(private http: HttpClient) { }
	get(endpoint: any, params?: any) {
		return this.http.get(endpoint, params);
	}

	post(endpoint: any, body: any, params?: any) {
		return this.http.post(endpoint, body, params);
	}
  getUserDetails() {
    return this.http.get<any>(environment.corePathV2 + '/user', {})
      .pipe(
        map(res => res || res),
        catchError(error => throwError(error))
      );
  }
  getAllCountries() {
    return this.http.get(environment.corePathV2 + "/countries").pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }
  getCategories(params: any) {
		return this.http.get(environment.offerPathV2 + "categories?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getMerchants(params: any) {
		// params['per_page'] = 100;
		return this.http.get(environment.offerPathV2 + "merchants?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
  getOffers(params: any, isNearBy?: boolean, isInternational?: boolean) {
    const offerUrlMapper = (): string => {
      return (isNearBy) ? 'nearby_offers' :
        (isInternational) ? 'international_offers' : 'offers';
    }
    return this.http.get(environment.offerPathV2 + offerUrlMapper() + '?elgble=true', {
      params: params
    }).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }
  redeemCampaignOffer(campaignId: string | number, offerId: string | number, formData: any) {
		let params = new HttpParams({
			fromObject: formData,
		});
		let httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
		};
		return this.http.post(environment.giftPathV2 + `/campaigns/` + campaignId + `/offer_redemption_options/` + offerId + `/redeem`, params.toString(), httpOptions).pipe(
			map((res) => res || []),
			catchError((error) => throwError(error))
		);
	}
  redeem(offer: any, params: any, exclude_project_id?: any) {
		if (exclude_project_id) {
			const httpOptions = {
				headers: new HttpHeaders({
					'exclude_project_id': 'true'
				})
			}
			return this.http.post(environment.offerPathV2 + "/offers/" + offer.id + "/redeem?elgble=true", params, httpOptions).pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
		}
		else {
			return this.http.post(environment.offerPathV2 + "/offers/" + offer.id + "/redeem?elgble=true", params).pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
		}
	}

	getOfferDetail(offer:any,params:any){
		return this.http.post(environment.offerPathV2 + "/offers/" + offer.id, params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

  searchMerchants(params: any) {
		// params['per_page'] = 100;
		return this.http.get(environment.offerPathV2 + "/merchants/search", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getRedemptions(params = {}) {
		return this.http.get(environment.offerPathV2 + "/redemptions?paginated", { params: params }).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	cancelBooking(params:any) {
		return this.http.post(environment.apiUrlBsV1 + 'bookings/cancel_booking', params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getTerms(id:any) {
		return this.http.get(environment.offerPathV2 + "/offers/" + id + '/terms').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getGiftTerms(id:any) {
		return this.http.get(environment.offerPathV2 + "/gift_cards/" + id + '/terms').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getMembershipTerms(id:any) {
		return this.http.get(environment.offerPathV2 + "/memberships/" + id + '/terms').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getBookingDetailsById(id:any) {
		return this.http.get(environment.stagingUrl + `users/bookings/booking_details?booking_unique_id=${id}`).toPromise();
	}
}
