import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  apiUrl = environment.apiUrlPaV1;
  apiUrlBsV1 = environment.apiUrlBsV1;
  private pointData = new BehaviorSubject(0);
  pointData$ = this.pointData.asObservable();
  constructor(
    private http: HttpClient
  ) { }

  changePointsData(data: any) {
    this.pointData.next(data)
  }
  
  createPayment(data:any) {
    return this.http
      .post(this.apiUrl + 'payments', data)
      .toPromise();
  }

  getCardDetails(number:any) {
    // return this.http.get(this.apiUrl + 'payments/get_card_details?card_num=' + number);
    const param = {
      card_num: number
    }
    return this.http.post(this.apiUrl + 'payments/get_card_details', param);
  }

  getPaymentDetails(bookingId:any) {
    return this.http
      .get(this.apiUrl + 'payments/' + bookingId)
      .toPromise();
  }

  saveUserCard(data:any) {
    return this.http
      .post(this.apiUrl + 'payments/save_user_card', data)
      .toPromise();
  }

  getUserCards() {
    return this.http
      .post(this.apiUrl + 'payments/get_user_cards', {})
      .toPromise();
  }

  getAllPromoCode(service_name?: string) {
    return this.http
      .get(this.apiUrlBsV1 + `/promo_codes`);
    // .get(this.apiUrlBsV1 + `/promo_codes` + (service_name ? '?service_name=' + service_name : ''));
  }
}
