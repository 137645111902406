<app-back [text]="'Hotel Booking Details'" [link]="''"></app-back>
<div class="container page " *ngIf="!progress">
    <div class="to-show1">
       
        <div class="pad-top-17">
            <div class="pad-20 pad-bot-15 pad-bot-5">
                <div *ngIf="pageType === 'confirmation'"
                    class="checkout-message-top flex align-items-center justify-content-between bg pad-15">
                    <div  *ngIf="bookingData.attributes.status === 'booking_confirmed'">
                        <div class="d-flex justify-content-start">
                            <div class="confirm-icon flex align-center justify-center">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18 0C8.064 0 0 8.064 0 18C0 27.936 8.064 36 18 36C27.936 36 36 27.936 36 18C36 8.064 27.936 0 18 0ZM14.4 27L5.4 18L7.938 15.462L14.4 21.906L28.062 8.244L30.6 10.8L14.4 27Z"
                                        fill="#27AE60" />
                                </svg>
                            </div>
                            
                            <div class="confirm-info pad-left-12">
                                <div class="f700 fnt-16">Congratulations! your hotel booking is confirmed</div>
                            </div>
                        </div>
                        <p class="mar-top-15 fnt-12 ln-20">We have sent the booking details to {{bookingData.attributes?.details?.attributes?.user_pack?.mobile_no}} and {{bookingData.attributes?.details?.attributes?.user_pack?.email}}.</p>
                        <div class="fnt-13">
                            Booking ID : <span class="f700">{{bookingData.attributes?.unique_id}}</span>
                        </div>
                    </div>
                    <div *ngIf="bookingData.attributes.status === 'booking_failed'" class="cancelled-status flex">
                        <div class="failed-icon flex align-center justify-center">
                            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.29438 7.65306C5.29438 7.81544 5.36132 7.97117 5.48046 8.08598C5.59959 8.2008 5.76118 8.26531 5.92967 8.26531C6.09816 8.26531 6.25975 8.2008 6.37888 8.08598C6.49802 7.97117 6.56496 7.81544 6.56496 7.65306C6.56496 7.49068 6.49802 7.33496 6.37888 7.22014C6.25975 7.10532 6.09816 7.04082 5.92967 7.04082C5.76118 7.04082 5.59959 7.10532 5.48046 7.22014C5.36132 7.33496 5.29438 7.49068 5.29438 7.65306ZM5.50615 3.77551V6.12245C5.50615 6.17857 5.55379 6.22449 5.61203 6.22449H6.24731C6.30555 6.22449 6.35319 6.17857 6.35319 6.12245V3.77551C6.35319 3.71939 6.30555 3.67347 6.24731 3.67347H5.61203C5.55379 3.67347 5.50615 3.71939 5.50615 3.77551ZM11.8021 9.38776L6.29628 0.204082C6.21423 0.067602 6.07261 0 5.92967 0C5.78673 0 5.64379 0.067602 5.56306 0.204082L0.0572464 9.38776C-0.105546 9.66071 0.0982752 10 0.423859 10H11.4355C11.7611 10 11.9649 9.66071 11.8021 9.38776ZM1.43238 9.03189L5.92967 1.52934L10.427 9.03189H1.43238Z"
                                    fill="white" />
                            </svg>
                        </div>
                        <div class="failed-info pad-left-12">
                            <div class="f700 fnt-16">Oops! your hotel booking is not confirmed</div>
                            <div class="fnt-12 mar-top-8">Please try again later</div>
                        </div>
                    </div>
                    <div *ngIf="bookingData.attributes.status === 'booking_cancelled'" >
                        <div class="d-flex justify-content-start">
                            <div class="failed-icon flex align-center justify-center">
                                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.29438 7.65306C5.29438 7.81544 5.36132 7.97117 5.48046 8.08598C5.59959 8.2008 5.76118 8.26531 5.92967 8.26531C6.09816 8.26531 6.25975 8.2008 6.37888 8.08598C6.49802 7.97117 6.56496 7.81544 6.56496 7.65306C6.56496 7.49068 6.49802 7.33496 6.37888 7.22014C6.25975 7.10532 6.09816 7.04082 5.92967 7.04082C5.76118 7.04082 5.59959 7.10532 5.48046 7.22014C5.36132 7.33496 5.29438 7.49068 5.29438 7.65306ZM5.50615 3.77551V6.12245C5.50615 6.17857 5.55379 6.22449 5.61203 6.22449H6.24731C6.30555 6.22449 6.35319 6.17857 6.35319 6.12245V3.77551C6.35319 3.71939 6.30555 3.67347 6.24731 3.67347H5.61203C5.55379 3.67347 5.50615 3.71939 5.50615 3.77551ZM11.8021 9.38776L6.29628 0.204082C6.21423 0.067602 6.07261 0 5.92967 0C5.78673 0 5.64379 0.067602 5.56306 0.204082L0.0572464 9.38776C-0.105546 9.66071 0.0982752 10 0.423859 10H11.4355C11.7611 10 11.9649 9.66071 11.8021 9.38776ZM1.43238 9.03189L5.92967 1.52934L10.427 9.03189H1.43238Z"
                                        fill="white" />
                                </svg>
                            </div>
                        
                            <div class="confirm-info pad-left-12">
                                <div class="f700 fnt-16">Your hotel booking has been Cancelled</div>
                            </div>
                        </div>
                        <p class="mar-top-15 fnt-12 ln-20">We have sent the cancellation details to
                            {{bookingData.attributes?.details?.attributes?.user_pack?.mobile_no}} and
                            {{bookingData.attributes?.details?.attributes?.user_pack?.email}}.</p>
                        <div class="fnt-13">
                            Booking ID : <span class="f700">{{bookingData.attributes?.unique_id}}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <ng-container *ngIf = "pageType === 'history'">
                <div class="each-strip flex space-between align-center">
                    <div>
                        Booking ID : <span class="f700">{{bookingData.attributes?.unique_id}}</span>
                    </div>
                    <div>
                        <img src="https://images.poshvine.com/icon/indie/printer-icon.svg" class="img-fluid" alt="print">
                    </div>
                </div>
                <div class="each-strip">
                    <app-status-label class="status-text"
                        [status]="getStatus(bookingData.attributes?.details?.attributes?.from_date, bookingData.attributes.status)">
                    </app-status-label>
                </div>
            </ng-container>
            
            

            <section class="info-box pad-20 pad-top-15">
                <section class="info-top-bar pad-15">
                    <div class="fnt-18 f700">
                        {{bookingData.attributes.details?.attributes?.hotel_details?.name}}
                    </div>
                    <div class="fnt-12 pad-top-3">
                        {{bookingData.attributes?.details?.attributes?.hotel_details.address}}
                    </div>
                </section>
                <div class="info-mid pad-15">
                    <div class="flex space-between fnt-12">
                        <div class="item-left">
                            <div class="label ">Check - In</div>
                            <div>{{ bookingData.attributes.details.attributes?.from_date | date: 'EEE, dd MMM yyyy' }}</div>
                        </div>
                        <div class="item-right">
                            <div class="label">Check - In</div>
                            <div>{{ bookingData.attributes.details.attributes?.to_date | date: 'EEE, dd MMM yyyy' }}</div>
                        </div>
                    </div>
                    <div class="flex space-between fnt-12 mar-top-15">
                        <div class="item-left">
                            <div class="label ">Rooms</div>
                            <div>{{roomCount}} {{roomName}}</div>
                        </div>
                        <div class="item-right">
                            <div class="label">Guests</div>
                            <div>
                                <span>{{bookingData?.attributes.details?.attributes.user_count?.adults }} </span>
                                <span>{{bookingData?.attributes.details?.attributes.user_count?.adults >= 2 ? 'Adults' : 'Adult'}}, </span>
                                <span *ngIf="bookingData?.attributes.details?.attributes.user_count">{{bookingData.attributes?.details?.attributes.user_count?.children }} </span>
                                <span *ngIf="bookingData?.attributes.details?.attributes.user_count">{{bookingData.attributes?.details?.attributes.user_count?.children >= 2 ? 'Children' : 'Child'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="mar-top-10 traveller-box">
                        <div class="booked-by">
                            Booked by {{bookingData.attributes?.details?.attributes.user_pack.first_name}}
                            {{bookingData.attributes?.details?.attributes.user_pack.last_name}}
                        </div>
                        <div>
                            <div class="phone-email">
                                Phone : {{bookingData.attributes?.details?.attributes.user_pack.mobile_no}}
                            </div>
                            
                            <div class="phone-email">
                                Email : {{bookingData.attributes?.details?.attributes.user_pack.email}}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            <section class="pad-20 fare-details">
                <div class="fare-details-in">
                    <div class="pad-15 fnt-16 f700 pad-bot-10 pad-top-20">Fare Details</div>
                    <div class="booking-details" *ngIf="(bookingData.attributes.details?.attributes | json) != '{}'">
                        <div class="">
                            <div class="d-flex flex-column">
                                <div class="pad-15 d-flex align-items-center justify-content-between">
                                    <div class="check-in">Hotel Charges</div>
                                    <div class="check-in-date">
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{(bookingData.attributes.details?.attributes.fare_breakup.base) | indianCurrency}}</span>
                                    </div>
                                </div>
                                
                                <div *ngIf="bookingData.attributes.details?.attributes.partner_name=='yatra'">
                                    <div *ngFor="let tax of bookingData.attributes.details?.attributes.fare_breakup.tax_breakup;">
                                        <div class="pad-15 pad-top-6 pad-bot-6 d-flex align-items-center justify-content-between">
                                            <div class="check-in">
                                                <div *ngIf="tax.type === 'HOTEL_GST'">Hotel GST</div>
                                                <div *ngIf="tax.type !== 'HOTEL_GST'">{{tax.type | removeUnderscore | titlecase}}</div>
                                            </div>
                                            <div class="check-in-date">
                                                <span class="mar-right-2">+</span>
                                                <span class="rupee-symbol mar-right-2">₹</span>
                                                <span>{{(tax.amount) | indianCurrency}}</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div *ngFor="let tax of bookingData.attributes.details?.attributes.fare_breakup.charges;">
                                        <div class="pad-15 pad-top-6 pad-bot-6 d-flex align-items-center justify-content-between">
                                            <div class="check-in">{{tax.type | removeUnderscore | titlecase}}</div>
                                            <div class="check-in-date">
                                                <span class="mar-right-2">+</span>
                                                <span class="rupee-symbol mar-right-2">₹</span>
                                                <span>{{(tax.amount) | indianCurrency}}</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div *ngFor="let tax of bookingData.attributes.details?.attributes.fare_breakup.discounts;">
                                        <div class="pad-15 pad-top-6 pad-bot-6 deduction d-flex align-items-center justify-content-between">
                                            <div class="check-in" style="color: var(--primary);">Hotel Offer</div>
                                            <div class="check-in-date" style="color: var(--primary);">
                                                <span class="mar-right-2">-</span>
                                                <span class="rupee-symbol mar-right-2">₹</span>
                                                <span>{{(tax.amount) | indianCurrency}}</span>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    
                                </div>
                                <div *ngIf="bookingData.attributes.details?.attributes.partner_name=='mms'">
                                    <div class="pad-15 pad-top-6 pad-bot-6 d-flex align-items-center justify-content-between">
                                        <div class="check-in">Taxes</div>
                                        <div class="check-in-date">
                                            <span class="mar-right-2">+</span>
                                            <span class="rupee-symbol mar-right-2">₹</span>
                                            <span>{{(bookingData.attributes.details?.attributes.fare_breakup.taxes)| indianCurrency
                                                }}</span>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div class="pad-15 pad-top-6 pad-bot-6 d-flex align-items-center justify-content-between"
                                    *ngIf="bookingData.attributes.details?.attributes.fare_breakup.convenience_fee">
                                    <div class="check-in">Convenience Fees<br><span class="fnt-12">(inclusive of GST)</span></div>
                                    <div class="check-in-date">
                                        <span class="mar-right-2">+</span>
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{(bookingData.attributes.details?.attributes.fare_breakup.convenience_fee) |
                                            indianCurrency}}</span>
                                    </div>
                                </div>
                                
                                <div class="pad-15 pad-top-6 pad-bot-6 total d-flex align-items-center justify-content-between"
                                    *ngIf="bookingData.attributes?.promo_code_details?.code">
                                    <div class="check-in">Total Amount</div>
                                    <div class="check-in-date boldify">
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{bookingData.attributes?.details?.attributes?.fare_breakup?.price | indianCurrency}}</span>
                                    </div>
                                </div>
                                
                                <div class="pad-15 pad-top-6 pad-bot-6 d-flex justify-content-between align-items-top  flight-details"
                                    *ngIf="bookingData.attributes?.promo_code_details?.code">
                                    <div class="check-in" style="color: var(--primary);">
                                        Coupon Discount
                                    </div>
                                    <div class="check-in-date" style="color: var(--primary);">
                                        <span class="mar-right-2">-</span>
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{bookingData.attributes?.details?.attributes?.fare_breakup?.discount | indianCurrency
                                            }}</span>
                                    </div>
                                </div>
                               <hr class="divider">
                                <div class="f700 fnt-16 pad-15 pad-top-15 pad-bot-15 d-flex justify-content-between ">
                                    <div class="">
                                        Total Payable
                                    </div>
                                    <div class="">
                                        <span class="rupee-symbol mar-right-2">₹</span>
                                        <span>{{(bookingData.attributes?.details?.attributes?.fare_breakup?.discounted_price
                                            ?bookingData.attributes?.details?.attributes?.fare_breakup?.discounted_price
                                            :bookingData.attributes?.details?.attributes?.fare_breakup?.price ) | indianCurrency}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cash-points mar-top-15 fnt-16">
                    <div class="check-in f700">
                        Paid by Gems
                    </div>
                    <div class="check-in-date">
                        <ng-container *ngIf="getAmountPaidBy('points')">
                            <span class="rupee-symbol mar-right-2">₹</span>
                            <span>
                                {{getAmountPaidBy('points') | indianCurrency}}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!getAmountPaidBy('points')">
                            0
                        </ng-container>
                    </div>
                </div>
                <div class="card-pay mar-top-15 fnt-16" *ngIf = "getAmountPaidBy('payment_gateway')">
                    <div class="check-in">
                        Amount paid by Card
                        <span class="card-val">{{paidCardDetals?.attributes?.card_num}}</span>
                        <!-- <img
                                                          *ngIf="cardIcon" height="16" src="{{cardIcon}}"> -->
                    </div>
                    <div class="check-in-date">
                        <ng-container *ngIf="getAmountPaidBy('payment_gateway')">
                            <span class="rupee-symbol mar-right-2">₹</span>
                            <span>
                                {{getAmountPaidBy('payment_gateway') | indianCurrency}}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!getAmountPaidBy('payment_gateway')">
                            0
                        </ng-container>
                    </div>
                </div>
            </section>
            
            
            <section class="pad-20 pad-top-0 payment-details">
                <section class="payment-details-in">

                
                    <!-- <div >
                        <div class="pad-15 fnt-16 f700 pad-bot-10 pad-top-20">Payment Breakup</div>
                    </div>
                    <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                        <div class="check-in">
                            Amount Paid by Card <span style="letter-spacing: 3px">{{bookingData.attributes?.card_number |
                                creditCardMask}}</span>
                        </div>
                        <div class="check-in-date">
                            <ng-container *ngIf="getAmountPaidBy('payment_gateway')">
                                <span class="rupee-symbol mar-right-2">₹</span>
                                <span>
                                    {{getAmountPaidBy('payment_gateway') | indianCurrency}}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="!getAmountPaidBy('payment_gateway')">
                                0
                            </ng-container>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                        <div class="check-in">
                            Amount Paid by Gems
                        </div>
                        <div class="check-in-date">
                            <ng-container *ngIf="getAmountPaidBy('points')">
                                <span class="rupee-symbol mar-right-2">₹</span>
                                <span>
                                    {{getAmountPaidBy('points') | indianCurrency}}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="!getAmountPaidBy('points')">
                                0
                            </ng-container>
                        </div>
                    </div> -->
                    
                    <div class="booking-details-bck" *ngIf="cancellationDetails">
                        <div>Cancellation</div>
                    </div>
                    <div *ngIf="bookingData.attributes.status === 'booking_confirmed'" class="each-strip booking-details">
                        <div *ngIf="cancellationDetails">
                            <div class="each-strip flight-details" *ngFor="let policy of cancellationDetails.cancellation_policies;">
                                <div class="">
                                    {{policy}}
                                </div>
                            </div>
                            <div class="each-strip flight-details" *ngIf="cancellationDetails.refundable_amount">
                                <div class="">
                                    Refundable Amount : <span
                                        class="rupee-symbol">₹</span> {{cancellationDetails.refundable_amount | indianCurrency}}
                                </div>
                            </div>
                            <div class="each-strip flight-details">
                                <button *ngIf="bookingData.attributes.status!='booking_cancelled'" class="btn cancel-btn mar-bot-10" (click)=cancelOpt()>
                                    <span >Cancel Booking</span>
                                </button>
                            </div>
                        </div>
                        <div class="flight-details" *ngIf="!cancellationDetails">
                            <div class="dot-flashing"></div>
                        </div>
                    </div>
                    <div class="each-strip booking-details" *ngIf="bookingData.attributes.status === 'booking_cancelled'">
                        <div *ngIf="cancellationDetails">
                            <div class="each-strip flight-details" *ngFor="let policy of cancellationDetails.msg;">
                                <div class="">
                                    {{policy}}
                                </div>
                            </div>
                        </div>
  
                        <div class="d-flex justify-content-between align-items-top each-strip flight-details">
                            <div class=" f700">
                                Refund Amount
                            </div>
                            <div class="check-in-date">
                                <span class="rupee-symbol mar-right-2">₹</span>
                                <span>{{bookingData.attributes?.refund_amount | indianCurrency}}</span>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
                  <!-- <div class="cancel-booking pointer" (click)="cancelOpt()" *ngIf="bookingData.attributes.details?.attributes.partner_name=='yatra' && bookingData.attributes.status === 'booking_confirmed'  ">
                    Cancel Booking
                    </div> -->
        </div>
    </div>
   
</div>

<app-bottom-drawer [open]="showDrawer" (closeEvent)="closeDrawer()" [showTopbar]='true' [headerType] = "'generic'" [heading]="drawerHeading">
    <ng-container *ngIf = "drawerContent === 'cancelWarning'">
        <section class="pad-top-10 pad-bot-30">
            <div class="fnt-14 pad-top-10 pad-bot-10">Are you sure you want to cancel the booking?</div>
            <div class="f600 pad-bot-10">{{bookingData?.attributes?.details?.attributes?.hotel_details?.name}}</div>
            <div class="pad-bot-15">
                <div class="flex space-between fnt-12">
                    <div class="item-left">
                        <div class="label ">Check - In</div>
                        <div>{{ bookingData?.attributes?.details?.attributes?.from_date | date: 'EEE, dd MMM yyyy' }}</div>
                    </div>
                    <div class="item-right">
                        <div class="label">Check - In</div>
                        <div>{{ bookingData?.attributes?.details?.attributes?.to_date | date: 'EEE, dd MMM yyyy' }}</div>
                    </div>
                </div>
            </div>
            <div class="flex space-between f700">
                <div>
                    Refundable Amount
                </div>
                <div>
                    <span class="rupee-symbol">₹</span> {{cancellationDetails?.refundable_amount | indianCurrency}}
                </div>
            </div>
        </section>
        <app-checkout-footer [showQuantity]="false" [btnTitle]="'Confirm Cancel'" (buttonClickEvent) = "postCancelReq()"></app-checkout-footer>
    </ng-container>
   
    <ng-container *ngIf="drawerContent === 'cancelSuccess'">
        <section class="pad-top-20 pad-bot-30 cancel-response-container">
            <div class="fnt-16 cancel-response">{{cancellationResponse?.msg}}</div>
            <div class="flex space-between f700 pad-top-15">
                <div>
                    Refundable Amount
                </div>
                <div>
                    <span class="rupee-symbol">₹</span> {{cancellationResponse?.refundable_amount | indianCurrency}}
                </div>
            </div>
            <div class="note fnt-13 pad-top-10">Your refund amount will be credited to your Payment Account within 7 business days.</div>
        </section>
        <app-checkout-footer [showQuantity]="false" [btnTitle]="'Back to home'" (buttonClickEvent)="goToHome()">
        </app-checkout-footer>
    </ng-container>
    <ng-container *ngIf="drawerContent === 'cancelFailure'">
        <section class="pad-top-20 pad-bot-30 cancel-response-container">
            <div class="fnt-16 cancel-response">{{cancellationResponse?.msg}}</div>
        </section>
        <app-checkout-footer [showQuantity]="false" [btnTitle]="'Back to home'" (buttonClickEvent)="goToHome()">
        </app-checkout-footer>
    </ng-container>
    <ng-container *ngIf="drawerContent === 'cancelReview'">
        <section class="pad-top-20 pad-bot-30 cancel-response-container">
            <div class="fnt-16 cancel-response">Your booking is either yet to be cancelled or cannot be cancelled.</div>
        </section>
        <app-checkout-footer [showQuantity]="false" [btnTitle]="'Back to home'" (buttonClickEvent)="goToHome()">
        </app-checkout-footer>
    </ng-container>
</app-bottom-drawer>
<app-skeleton-loader *ngIf="progress" [type]="'circle'"></app-skeleton-loader>