import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

export enum queryParamMap {
  GIFTCARD = "GiftCard",
  MEMBERSHIP = "MembershipBooking",
  FLIGHT =  "FlightItinerary",
  DONATION =  "donation",
  HOTELS = "hotel",
  GIFTING = 'gifting'
}

@Injectable()
export class PaymentService {
  public userDetails?: any;
  public bookingDetails?: any;

  constructor(private http: HttpClient) {}

  public fetchBookingId(payload: any) {
    return this.http
      .post(environment.apiUrlBsV1 + `carts/checkout`, payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  public createMembershipBooking(payload: any) {
    return this.http
      .post(environment.apiUrlBsV1 + `memberships/create_booking`, payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  public updateMembershipBooking(payload: any) {
    return this.http
      .post(environment.apiUrlBsV1 + `memberships/update_booking`, payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  public getBookingDetails(id: any) {
    return this.http
      .get(environment.apiUrlBsV1 + `/bookings/${id}`)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  public fetchBurnRatio() {
    return this.http
      .get(environment?.apiUrlLsV1 + "burn_rules/burn_ratio")
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  public addUserData(payload: any) {
    return this.http
      .post(environment.apiUrlBsV1 + `gift_cards/add_user_data`, payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  public makePayment(payload: any) {
    return this.http.post(environment.apiUrlPaV1 + `payments`, payload).pipe(
      map((res) => res || []),
      catchError((error) => throwError(error))
    );
  }
  getPaymentDetails(bookingId:any) {
    return this.http
      .get(environment.apiUrlPaV1 + 'payments/' + bookingId)
      .toPromise();
  }

  getCouponOffers() {
    return this.http.get(environment.apiUrlBsV1 + `promo_codes`)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      )
  }

  applyCoupon(bookingId: any, couponName: String) {
    return this.http.post(environment.apiUrlBsV1 + `promo_codes/apply?code=${couponName}&booking_id=${bookingId}`, '')
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  removeCoupon(bookingId: any, couponName: String) {
    return this.http.post(environment.apiUrlBsV1 + `promo_codes/remove?code=${couponName}&booking_id=${bookingId}`, '');
  }
  
  public fetchCardDetails(payload: any) {
    return this.http
      .post(environment.apiUrlPaV1 + `payments/get_card_details`, payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }


  public addUserDataGifting(payload: any) {
    return this.http
      .post(environment.apiUrlBsV1 + `fnp/add_user_data`, payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
}
