<div class="success-container">
  <div class="booking-icon" class="mar-bot-16">
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_25595)">
        <path
          d="M35.9995 6.00073C19.4395 6.00073 5.99951 19.4407 5.99951 36.0007C5.99951 52.5607 19.4395 66.0007 35.9995 66.0007C52.5595 66.0007 65.9995 52.5607 65.9995 36.0007C65.9995 19.4407 52.5595 6.00073 35.9995 6.00073Z"
          fill="#27AE60"
        />
        <path
          d="M22.6216 38.4937L30.2512 46.3592C31.0801 47.2136 32.4402 47.2136 33.2478 46.3592L41.3131 38.0446L49.3784 29.73C50.2072 28.8756 50.2072 27.4953 49.3784 26.6408C48.5495 25.7864 47.2106 25.7864 46.3818 26.6408L31.7602 41.7144L25.6182 35.4045C24.7894 34.5501 23.4505 34.5501 22.6216 35.4045C21.7928 36.259 21.7928 37.6393 22.6216 38.4937Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_25595">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <span class="f700 fnt-16 mar-bot-20">Payment successful</span>
  <div class="success-desc">
    <span>
      You will receive your {{ bookingType }} details on<br/>
      <b>{{
        bookingData?.attributes?.booking_details?.user_details?.mobile
      }}</b>
      and
      <b>{{ bookingData?.attributes?.booking_details?.user_details?.email }}</b
      >.
    </span>
  </div>
  <div
    class="order-desc"
    *ngIf="bookingType === 'Gift card' || bookingType === 'Membership'"
  >
    <ng-container *ngIf="bookingType === 'Gift card'">
      <div
        class="order-detail"
        *ngFor="
          let booking of bookingData?.attributes?.details?.attributes
            ?.gift_card_orders;
          let i = index
        "
      >
        <div class="d-flex justify-content-between">
          <div class="d-flex col-7">
            <img
              class="merchant-img mar-right-10"
              loading="lazy"
              [src]="
                booking?.attibutes?.gift_card_data?.image_url ||
                'https://images.poshvine.com/b/default-thumbnail-square.jpg'
              "
              alt="merchant image"
            />
            <div class="d-flex flex-column ellipsis">
              <span class="f700 ellipsis"
                >{{ booking?.attributes?.gift_card_data?.title }} Gift
                Card</span
              >
              <span class="fnt-12 text-secondary"
                >{{ booking?.attributes?.quantity }} units</span
              >
            </div>
          </div>
          <span class="fnt-12 text-secondary mar-right col-2 text-center"
            >₹{{ booking?.attributes?.denomination | indianCurrency }}</span
          >
          <span
            class="text-primary fnt-12 f700 col-3 text-center"
            (click)="
              goTo(
                booking?.attributes?.vouchers[0]?.activation_url
                  ? booking?.attributes?.vouchers[0]?.activation_url
                  : booking?.attributes?.gift_card_data?.merchant_url
              )
            "
            >Redeem</span
          >
        </div>
        <hr
          *ngIf="
            i !==
            bookingData?.attributes?.details?.attributes?.gift_card_orders
              ?.length -
              1
          "
        />
      </div>
    </ng-container>
    <ng-container *ngIf="bookingType === 'Membership'">
      <div
        class="order-detail"
        let
        booking="bookingData?.attributes?.details?.attributes?.gift_card_orders"
      >
        <div class="d-flex justify-content-between">
          <div class="d-flex col-10">
            <img
              class="merchant-img mar-right-10"
              loading="lazy"
              [src]="
                bookingData?.attributes?.details?.attributes?.membership_details
                  ?.image_url ||
                'https://images.poshvine.com/b/default-thumbnail-square.jpg'
              "
              alt="merchant image"
            />
            <div class="d-flex flex-column ellipsis">
              <span class="f700 ellipsis">{{
                bookingData?.attributes?.details?.attributes?.membership_details
                  ?.title
              }}</span>
            </div>
          </div>
          <span class="fnt-12 text-secondary col-2"
            >₹{{
              bookingData?.attributes?.details?.attributes?.membership_details
                ?.offer_price | indianCurrency
            }}</span
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="redemption-btn-container">
  <button
    class="btn btn-primary btn-sm btn-pill mar-bot-20"
    (click)="navigateTo('history')"
  >
    My Orders
  </button>
  <span class="text-primary f700" (click)="navigateTo('app')">Go to Homepage</span>
</div>
