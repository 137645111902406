import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit, OnDestroy {

  @Input() bookingData: any;
  @Input() public bookingType?: string;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public navigateTo(routeString: string) {
    if (routeString === 'app'){
      (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({status:"EXIT_WEB-VIEW"}));
    } else {
      if (this.bookingType) {
        const navigationExtras =  {
          preserveFragment: true,
          queryParams: {
            app: this.bookingType === 'Gift card' ? 'Gift Cards' : `${this.bookingType}s`
          }
        }
        this.router.navigate([routeString], navigationExtras)
      } else {
        this.router.navigate([routeString]);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public goTo(url:any){
    if (url) {
      window.open(url);
    }
  }
}
