import * as fromRouter from "@ngrx/router-store";
import {
  createFeatureSelector,
  ActionReducerMap,
  MetaReducer,
  createAction,
} from "@ngrx/store";
import { getSelectors } from "@ngrx/router-store";
export interface State {
  router: fromRouter.RouterReducerState<any>;
}

export const LOGOUT = createAction("LOGOUT");

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
};

export const metaReducers: MetaReducer<State>[] = [logout];

function logout(reducer: any) {
  return function (state: any, action: any) {
    return reducer(action.type === "LOGOUT" ? {} : state, action);
  };
}

export const selectRouter =
  createFeatureSelector<fromRouter.RouterReducerState<any>>("router");

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors();
