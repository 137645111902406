import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor() {
    this.addScript(`https://maps.googleapis.com/maps/api/js?key=${environment.gmApiKey}`);
  }

  ngOnInit() {
  console.timeEnd('logTime');
  console.log('on init log')
  console.time('logsso');
  }
 
  addScript(linkurl: any) {
    var headID = document.getElementsByTagName('head')[0];
    var link = document.createElement('script');
    link.src = linkurl;
    link.async = true;
    headID.appendChild(link);
  }
  
}
