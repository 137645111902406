import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Subscription } from 'rxjs/Subscription';
import { DataTransferService } from '../../services/common/datatransfer.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser: any;
  constructor(
    public _authService: AuthService,
    private elementRef: ElementRef,
    private dataTransferService: DataTransferService,
    public _commonService: CommonService
  ) { }
  refreshSubscription: Subscription = new Subscription;
  @HostListener('unloaded')
  ngOnDestroy() {
    // this.dataTransferService.nextMessage(null)
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe()
    }
    this.elementRef.nativeElement.remove();
  }
  ngOnInit(): void {
    // this.getCards(false);
    this.checkRefresh();

  }
  checkRefresh() {
    
    if (!this.refreshSubscription) {
      this.refreshSubscription = this.dataTransferService.sharedRefreshEvent.subscribe(res => {
        if (res) {
  
          // this.checkName();
        }
      });
    }
  }
  







}
