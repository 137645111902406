<ng-container *ngIf="type === 'generic'" [ngSwitch]="currentStatus" class="app-classs">
  <span *ngSwitchCase="'confirmed'" class="confirmed-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'upcoming'" class="upcoming-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'cancelled'" class="cancelled-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'initiated'" class="upcoming-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'booking_in_progress'" class="upcoming-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'payment_link_sent'" class="enquiry-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'cancellation_requested'" popover="{{removeBooking(status)}}" triggers="mouseenter:mouseleave" class="cancelled-status text-truncate">{{removeBooking(status)}}</span>
  <span *ngSwitchCase="'pending'" class="enquiry-status">{{removeBooking(status) | removeUnderscore}}</span>
</ng-container>
<ng-container *ngIf="type === 'offer'" [ngSwitch]="currentStatus" class="app-classs">
  <span *ngSwitchCase="'successful'" class="confirmed-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'partially_succesfull'" class="upcoming-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'cancelled'" class="cancelled-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'booking_initiated'" class="upcoming-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'booking_created'" class="upcoming-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'booking_in_progress'" class="upcoming-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'payment_link_sent'" class="enquiry-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'failed'" popover="{{removeBooking(status)}}" triggers="mouseenter:mouseleave" class="cancelled-status text-truncate">{{removeBooking(status)}}</span>
  <span *ngSwitchCase="'pending'" class="enquiry-status">{{removeBooking(status) | removeUnderscore}}</span>
  <span *ngSwitchCase="'booking_pending'" class="enquiry-status">{{removeBooking(status) | removeUnderscore}}</span>
</ng-container>