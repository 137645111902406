<ng-container *ngIf="type === 'membership' || type === 'giftCard'">
    <div class="skeleton card" [ngClass]="type" *ngFor="let item of generateFake(5)">
        <ngx-skeleton-loader class="card-img-top" appearance="line" [theme]="{ height: '75px',width: '100%'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader class="card-img-inline" appearance="circle" [theme]="{ height: '33px',width: '33px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader class="plans-title-inline" appearance="line" [theme]="{ height: '19px',width: '63px'}">
        </ngx-skeleton-loader>
        <div class="card-body">
            <ngx-skeleton-loader class="card-title" appearance="line" [theme]="{ height: '12px',width: '100%'}">
            </ngx-skeleton-loader>
            <div class="amt-text" *ngIf="type === 'membership'">
                <ngx-skeleton-loader appearance="line" [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader>
            </div>
            <ngx-skeleton-loader class="card-text" appearance="line" [theme]="{ height: type === 'giftCard' ? '12px' : '54px',width: '100%'}">
            </ngx-skeleton-loader>
            <div class="flex-fill"></div>
            <span class="separator"></span>
            <ngx-skeleton-loader class="btn btn-sm btn-pill" [theme]="{ height: '33px'}"></ngx-skeleton-loader>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'gifting'">
    <div class="skeleton card gifting" [ngClass]="type" *ngFor="let item of generateFake(5)">
        <ngx-skeleton-loader class="card-img-top" appearance="line" [theme]="{ height: '75px',width: '100%'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader class="card-img-inline" appearance="circle" [theme]="{ height: '33px',width: '33px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader class="plans-title-inline" appearance="line" [theme]="{ height: '19px',width: '63px'}">
        </ngx-skeleton-loader>
        <div class="card-body">
            <ngx-skeleton-loader class="card-title" appearance="line" [theme]="{ height: '12px',width: '100%'}">
            </ngx-skeleton-loader>
          
            <ngx-skeleton-loader class="card-text" appearance="line" [theme]="{ height: type === 'gifting' ? '12px' : '54px',width: '100%'}">
            </ngx-skeleton-loader>
            <div class="flex-fill"></div>
            
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'redeem'">
    <div class="skeleton redeem" >
        <ngx-skeleton-loader class="congratulations-panel" [theme]="{ height: '78px'}" appearance="line" >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader class="sec-header" appearance="line" [theme]="{ height: '62px'}">
        </ngx-skeleton-loader>
        <div style="width:100%; height:10px;background:white;">&nbsp;</div>

        <ngx-skeleton-loader class="token" appearance="line" [theme]="{ height: '90px'}">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader class="footer" appearance="line" [theme]="{ height: '50px'}">
        </ngx-skeleton-loader>
        <div style="width:100%; height:30px;background:white;">&nbsp;</div>
        <ngx-skeleton-loader class="" appearance="line" [theme]="{ height: '50px'}">
        </ngx-skeleton-loader>
        <div style="width:100%; height:40px;background:white;">&nbsp;</div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'flights'">
    <div class="loader-contain flex align-center justify-center ">
        <div class="text-center">
            <img class="flight-gif" src="./assets/flight-loader.gif">
            <div class="fnt-12 mar-top-10">{{desc}}</div>
        </div>
        
    </div>
    <div class="overlay flight-overlay"></div>
</ng-container>
<ng-container *ngIf="type === 'circle'">
    <div class="loader1 dot-spin"></div>
</ng-container>
<ng-container *ngIf="type === 'button'">
    <img class="spin-loader img-fluid" src="./assets/spin.gif">
</ng-container>
<ng-container *ngIf="type === 'singleline'">
    <ngx-skeleton-loader  appearance="line"  [theme]="{ height: '40px',width: '100%'}">
    </ngx-skeleton-loader>
</ng-container>
<ng-container *ngIf="type === 'cartCards'">
    <div class="cart-item">
        <ngx-skeleton-loader class="img-sec" [theme]="{ height: '68px', width:'68px'}" appearance="line" >
        </ngx-skeleton-loader>
        <div class="title-holders">
            <ngx-skeleton-loader class="heading" [theme]="{ height: '21px', width:'200px'}" appearance="line" >
            </ngx-skeleton-loader>
            <ngx-skeleton-loader class="subtitle" [theme]="{ height: '21px',width:'200px'}" appearance="line" >
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'payment-detail'">
    <div class="payment-detail">
        <ngx-skeleton-loader class="img-sec" [theme]="{ height: '68px', width:'68px'}" appearance="line" >
        </ngx-skeleton-loader>
        <div class="title-holders">
            <ngx-skeleton-loader class="heading" [theme]="{ height: '21px', width:'200px'}" appearance="line" >
            </ngx-skeleton-loader>
            <ngx-skeleton-loader class="subtitle" [theme]="{ height: '21px',width:'200px'}" appearance="line" >
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type === 'history'">
    <div class="skeleton card history" *ngFor="let item of generateFake(5)">
        <!-- <ngx-skeleton-loader class="card-img-top" appearance="line" [theme]="{ height: '75px',width: '100%'}">
        </ngx-skeleton-loader> -->
        <div class="head-panel">
            <ngx-skeleton-loader class="card-img-inline" appearance="circle" [theme]="{ height: '55px',width: '55px'}">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader class="plans-title-inline" appearance="line" [theme]="{ height: '35px',width: '250px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="card-body">
            <ngx-skeleton-loader class="card-title" appearance="line" [theme]="{ height: '27px',width: '100%'}">
            </ngx-skeleton-loader>

            <!-- <ngx-skeleton-loader class="card-text" appearance="line" [theme]="{ height: '27px',width: '100%'}">
            </ngx-skeleton-loader> -->
            <div class="flex-fill"></div>
            <span class="separator"></span>
            <ngx-skeleton-loader class="btn btn-sm btn-pill" [theme]="{ height: '33px',width: '30%'}"></ngx-skeleton-loader>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'details'">
    
            <div class="detail-holder">
                <ngx-skeleton-loader class="card-img-inline" appearance="circle" [theme]="{ height: '205px',width: '100%'}">
                </ngx-skeleton-loader>
    
                <ngx-skeleton-loader class="card-img-inline" appearance="circle" style="margin-bottom: 30px;" [theme]="{ height: '30px',width: '100%'}">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader class="card-img-inline" appearance="circle" style="margin-bottom: 30px;" [theme]="{ height: '30px',width: '100%'}">
                </ngx-skeleton-loader>

                <div class="button-sec">
                    <ngx-skeleton-loader class="button-cs" appearance="line"  [theme]="{ height: '40px',width: '100%'}">
                    </ngx-skeleton-loader>
                </div>
            </div>
            
        
    
</ng-container>