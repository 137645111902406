import { ToastrService } from 'ngx-toastr';
import { GiftingService } from './../gifting.service';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  IMembership,
  IMembershipDetailData,
} from "src/app/models/membership.interface";
import { selectActiveMembership } from "src/app/store/selectors/membership.selectors";
import { CommonService } from "src/app/store/services/common.service";
import { MembershipService } from "src/app/store/services/membership.service";
import { queryParamMap } from "src/app/store/services/payment.service";

@Component({
  selector: "app-membership-checkout",
  templateUrl: "./gifting-checkout.component.html",
  styleUrls: ["./gifting-checkout.component.scss"],
})
export class GiftingCheckoutComponent implements OnInit, OnDestroy {
  public activeMembership: IMembership | undefined;
  public selectedPlan?: IMembershipDetailData;
  public taxPercent: number = 0.18;
  public amountDetails: {
    base?: number;
    tax?: number;
    total?: number;
  } = {
    base: 0,
    tax: 0,
    total: 0,
  };
  public totalCartValue: {
    sum?: number;
    quantity?: number;
  } = { sum: 0, quantity: 0 };
  protected listOfGiftingOptions:any[] = [];
  protected bookingDetail:any;
  protected loading = false;
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private giftingService: GiftingService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    // 207bae46-6c8f-48e4-b145-74841b641e93
    //payments/success/gifting/checkout-message/6e3671de-85a3-4a63-9018-f44204761a98
    this.loading = true;
    if(this.route.snapshot.params.booking_id)
    this.giftingService.getBookingById(this.route.snapshot.params.booking_id).subscribe(res=>{
      if(res && res.data){
        this.giftingService.getAllGiftingOptions().subscribe((resp) => {
          if (resp && resp.data && resp.data.gift_card_list) {
              this.listOfGiftingOptions = resp.data.gift_card_list;
              res?.data?.attributes?.details?.attributes?.fnp_orders.forEach((elem: any) => {
                  const findI = this.listOfGiftingOptions.findIndex((element: any) => 
                  element.id === 
                  elem?.attributes?.product_id);
                  if(findI != -1){
                    elem.attributes.name = this.listOfGiftingOptions[findI].product_name;
                    elem.attributes.image_url = this.listOfGiftingOptions[findI].images.image_link_1;
                  }
              });
          }
          this.bookingDetail = res.data;
          this.loading = false;
      }, (err: any) => {
          this.toaster.error('Something went wrong');
          this.loading = false;
      });
      } else {
        this.toaster.error('Something went wrong');
        this.loading = false;
      }
    })
  }

  ngOnDestroy(): void {
  }

  public navigateToListing() {
    this.router.navigate(["memberships"]);
  }
  handleBackEmitter(event:any){
    this.router.navigate(["gifting"]);
  }

}
