<div *ngIf="showContent" class="success-container">
  <div class="booking-icon" class="mar-bot-16">
    <svg
      *ngIf="bookingStatus == 'failure'"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_26443)">
        <path
          d="M35.9995 6.00073C19.4395 6.00073 5.99951 19.4407 5.99951 36.0007C5.99951 52.5607 19.4395 66.0007 35.9995 66.0007C52.5595 66.0007 65.9995 52.5607 65.9995 36.0007C65.9995 19.4407 52.5595 6.00073 35.9995 6.00073Z"
          fill="#D22630"
        />
        <path
          d="M45.8266 43.48L28.52 26.1733C27.872 25.5253 26.8213 25.5253 26.1733 26.1733C25.5253 26.8214 25.5253 27.872 26.1733 28.52L43.48 45.8267C44.128 46.4747 45.1786 46.4747 45.8266 45.8267C46.4747 45.1786 46.4747 44.128 45.8266 43.48Z"
          fill="white"
        />
        <path
          d="M28.52 45.8266L45.8267 28.52C46.4747 27.872 46.4747 26.8213 45.8267 26.1733C45.1786 25.5253 44.128 25.5253 43.48 26.1733L26.1733 43.48C25.5253 44.128 25.5253 45.1786 26.1733 45.8266C26.8214 46.4747 27.872 46.4747 28.52 45.8266Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_26443">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <svg
      *ngIf="bookingStatus !== 'failure'"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="36" cy="36" r="36" fill="#FCD995" />
      <path
        d="M31.2435 39.7906L44.7737 25.7009C45.6711 24.7664 47.1438 24.7664 48.0412 25.7009C48.9386 26.6354 48.9386 28.145 48.0412 29.0795L32.8773 44.8705C31.9799 45.8051 30.5302 45.8051 29.6328 44.8705L23.6731 38.6644C22.7756 37.7298 22.7756 36.2202 23.6731 35.2857C24.5705 34.3512 26.0201 34.3512 26.9175 35.2857L31.2435 39.7906Z"
        fill="white"
      />
      <path
        d="M35.7143 12.3143C35.7143 12.0737 35.7143 11.9534 35.7313 11.8239C35.8505 10.9153 36.7177 10.1228 37.6333 10.0857C37.7639 10.0805 37.8511 10.0883 38.0255 10.1041C42.2957 10.4894 46.4151 11.938 50.0004 14.3336C54.2291 17.1592 57.5249 21.1752 59.4712 25.8739C61.4174 30.5725 61.9267 35.7428 60.9345 40.7309C59.9423 45.719 57.4932 50.3008 53.897 53.897C50.3008 57.4932 45.719 59.9423 40.7309 60.9345C35.7428 61.9267 30.5725 61.4174 25.8739 59.4712C21.1752 57.5249 17.1592 54.2291 14.3336 50.0004C11.938 46.4151 10.4894 42.2957 10.1041 38.0255C10.0883 37.8511 10.0805 37.7639 10.0857 37.6333C10.1228 36.7177 10.9153 35.8505 11.8239 35.7313C11.9534 35.7143 12.0737 35.7143 12.3143 35.7143C12.6542 35.7143 12.8241 35.7143 12.9383 35.7274C13.8486 35.8324 14.4549 36.3756 14.659 37.2689C14.6846 37.381 14.7082 37.5953 14.7554 38.024C15.125 41.3773 16.2954 44.6051 18.1822 47.4289C20.4991 50.8964 23.7922 53.599 27.6451 55.1949C31.498 56.7909 35.7377 57.2084 39.8279 56.3948C43.9181 55.5812 47.6752 53.573 50.6241 50.6241C53.573 47.6752 55.5812 43.9181 56.3948 39.8279C57.2084 35.7377 56.7909 31.498 55.1949 27.6451C53.599 23.7922 50.8964 20.4991 47.4289 18.1822C44.6051 16.2954 41.3773 15.125 38.024 14.7554C37.5953 14.7082 37.381 14.6846 37.2689 14.659C36.3756 14.4549 35.8324 13.8486 35.7275 12.9383C35.7143 12.8241 35.7143 12.6542 35.7143 12.3143Z"
        fill="white"
      />
    </svg>
  </div>

  <span class="f700 fnt-16 mar-bot-20">{{
    bookingStatus == "failure" ? "Payment Failure" : "Order Initiated "
  }}</span>
  <div class="success-desc">
    <span *ngIf="bookingStatus == 'failure'"
      >Any amount debited will be credited back to your account in 3-5 working
      days.</span
    >
    <span *ngIf="bookingStatus !== 'failure'">
      You will receive your {{ bookingType }} details on<br/>
      <b>{{
        bookingData?.attributes?.booking_details?.user_details?.mobile
      }}</b>
      and
      <b>{{ bookingData?.attributes?.booking_details?.user_details?.email }}</b
      >.
    </span>
  </div>
</div>
<div class="redemption-btn-container">
  <button class="btn btn-primary btn-sm btn-pill mar-bot-20" *ngIf="bookingStatus !== 'failure'" (click)="navigateTo('history')">My Orders</button>
  <span class="text-primary f700" (click)="navigateTo('app')">Go to Homepage</span>
</div>
