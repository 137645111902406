import { Component, OnInit, Input, EventEmitter, Output, Renderer2, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-bottom-drawer",
  templateUrl: "./bottom-drawer.component.html",
  styleUrls: ["./bottom-drawer.component.scss"],
})
export class BottomDrawerComponent implements OnInit {
  

  @Input() open:boolean = false;
  @Input() showTopbar:boolean = false;
  @Input() heading:string = '';
  @Input() height:string = '';
  @Input() headerType:'custom'|'generic' = 'custom';
  @Input() public addPadding: boolean = true;
  @Output() closeEvent: EventEmitter<boolean>= new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2,
  ) { }

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.open && changes.open.currentValue) {
      this.renderer.addClass(document.body, 'modal-open');
    }
    else if (!changes.open?.firstChange && (!changes.open || !changes.open.currentValue)) {
      this.renderer.removeClass(document.body, 'modal-open');
    }
  }
  close(){
    this.removeClass();
    this.closeEvent.emit(true);
  }
  public removeClass(){
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
