<ng-container *ngIf="!showLoader">
  <app-bottom-drawer
    [open]="true"
    [addPadding]="false"
    (closeEvent)="navigateTo('')"
  >
    <app-payment-success
      *ngIf="bookingStatus === 'success'"
      [bookingData]="bookingData"
      [bookingType]="bookingType"
    ></app-payment-success>
    <app-payment-failure
      *ngIf="bookingStatus === 'failure' || bookingStatus === 'initiated'"
      [bookingData]="bookingData"
      [bookingType]="bookingType"
      [bookingStatus]="bookingStatus"
    ></app-payment-failure>
  </app-bottom-drawer>
</ng-container>
<ng-container *ngIf="showLoader">
  <app-skeleton-loader [type]="'circle'"></app-skeleton-loader>
</ng-container>
