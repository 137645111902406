import { PaymentService } from 'src/app/shared/services/payment/payment.service';
import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { IndianCurrency } from 'src/app/shared/pipes/indian-currency.pipe';
import * as _moment from 'moment';
import swal from 'sweetalert2';
import { HotelsApiService } from '../../hotels/service/hotels-api.service';
const moment = _moment;

@Component({
  selector: 'app-hotel-booking-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.scss'],
  providers: [IndianCurrency]
})
export class HotelBookingDetailsComponent implements OnInit {
  bookingId : any = '';
  bookingData:any;
  progress : boolean = true;
  roomName:any;
  roomCount:any;
  cardIcon:any;
  cancellationDetails:any;
  paymentDetails:any;
  pageType: string = '';
  paidCardDetals: any;
  protected cancellationResponse: any;
  @ViewChild('map1', {static: false}) mapRef1!: ElementRef;

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute, 
    private router: Router,
    private hotelApiService: HotelsApiService, 
    private indianCurrency: IndianCurrency,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.progress = true;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.bookingId = this.route.snapshot.params.booking_id;
    this.checkPageType();
        this.paymentService.getPaymentDetails(this.bookingId).then( (res: any) => {
          this.paymentDetails = res.data;
          this.getCardInfo();
        });
        this.hotelApiService.getBookingsById(this.bookingId).then( (resp:any) => {
          if(resp['data']['attributes']['details'].attributes.fare_breakup.charges){
            let hotel_gst_arr = resp['data']['attributes']['details'].attributes.fare_breakup.charges.find((charge:any) => charge.type === "EXTRA_GUEST_CHARGE")                    
            if(hotel_gst_arr){
              resp['data']['attributes']['details'].attributes.fare_breakup.base = resp['data']['attributes']['details'].attributes.fare_breakup.base + hotel_gst_arr.amount;
            }
          }
          this.bookingData = resp['data'];
          this.roomName = this.bookingData['attributes']['details'].attributes.rooms[0]['room_name'];
          this.roomCount = resp['data']['attributes']['details'].attributes.rooms[0]['room_count'];
          if(this.bookingData.attributes.status === 'booking_confirmed'){
            this.hotelApiService.checkCancellationCharges(this.bookingId).subscribe((resp:any) => {
              this.cancellationDetails = resp;
            });
          }
          this.progress = false;
    },
    (error)=>{});
  }
  checkPageType(){
    this.pageType = this.document.location.hostname.includes("history") ? 'history' : 'confirmation';
  }
  convertMyWay(){
    let title = document.title;
    document.title = this.bookingId;
    window.print();
    document.title = title;
  }

  getCardLink(cardType:any) {
    // return './../../../../assets/' + (appConstants.cardIconsMapping[cardType] ? appConstants.cardIconsMapping[cardType] : appConstants.cardIconsMapping['Unknown']);
    // return appConstants.cardIconsMapping[cardType]
    //   ? "./../../../../assets/" + appConstants.cardIconsMapping[cardType]
    //   : null;
    return null
  }

  getStatus(date:any, cur_status:any){
    if(this.checkIfUpcoming(date) && cur_status === 'booking_confirmed'){
      return 'upcoming';
    }else if(this.checkIfCompleted(date) && cur_status === 'booking_confirmed'){ 
      return 'booking_completed';
    }else{
      return cur_status;
    }
  }

  checkIfUpcoming(date:any){
    return moment(date).isAfter(moment().startOf('day'))
  }

  checkIfCompleted(date:any){
    return moment(date).isBefore(moment().startOf('day'))
  }
  
  cancelOpt(){
      if(this.cancellationDetails['status']==='success'){
        this.openDrawer('cancelWarning')
      }else{
        this.openDrawer('cancelReview')
        let cancellationMessage = '<p>'+this.cancellationDetails['msg'][0]+'</p>';
        // swal.fire({
        //   imageUrl: "assets/modal-error.png",
        //   imageHeight: 60,
        //   title: "Review Cancellation",
        //   html: cancellationMessage,
        //   showCancelButton: true,
        // })
      }
    // })
  }
  protected postCancelReq(){
    this.closeDrawer();
    this.progress = true;
    this.hotelApiService.cancelHotelBooking(this.bookingId).subscribe((resp:any) => {
    // let resp = {
    //   "status": "ncancelled",
    //   "msg": [
    //     "Booking has been cancelled."
    //   ],
    //   "refundable_amount": 3486,
    //   "cancellation_policies": [
    //     "Booking has been cancelled."
    //   ]
    // }
      this.progress=false;
    this.cancellationResponse = resp
    if (this.cancellationResponse?.status === "cancelled"){
      
      this.openDrawer('cancelSuccess')
        // swal.fire({
        //   // icon: "success",
        //   imageUrl: "assets/modal-info.png",
        //   imageHeight: 60,
        //   title: "Cancellation Successful",
        //   html: '<p> Refundable Amount : '+ '<span class="rupee-symbol">₹</span> ' +this.indianCurrency.transform(resp['refundable_amount'], null)+'</p>'+
        //   '<br><p>Your refund amount will be credited to your Payment Account within 7 business days.</p>',
        //   // showCancelButton: true,
        // }).then( res => {
        //   // this.ngOnInit();
        //   location.reload();
        // })
      }else{
      this.openDrawer('cancelFailure')
        // swal.fire({
        //   imageUrl: "assets/modal-error.png",
        //   imageHeight: 60,
        //   title: "Cancellation Failed",
        //   html: '<p>'+resp['msg'][0]+'</p>',
        //   // showCancelButton: true,
        // }).then( res => {
        //   // this.ngOnInit();
        //   location.reload();
        // })
      }
    })
  }
  back(){
    history.back();
  }
  goToHome(){
    this.router.navigate(['hotels'])
  }
  getAmountPaidBy(type:any) {
    if (this.paymentDetails &&
      this.paymentDetails.attributes &&
      this.paymentDetails.attributes.transactions &&
      this.paymentDetails.attributes.transactions.data &&
      this.paymentDetails.attributes.transactions.data.length) {
      for (const each of this.paymentDetails.attributes.transactions.data) {
        if (each.attributes.txn_type == type) {
          return each.attributes.amount ? each.attributes.amount : 0;
        }
      }
    }
    return 0;
  }
  getCardInfo(): void {
    if (this.paymentDetails.attributes.transactions.data &&
      this.paymentDetails.attributes && this.paymentDetails.attributes.transactions &&
      this.paymentDetails.attributes.transactions.data) {
      this.paidCardDetals = this.paymentDetails.attributes.transactions.data.find((elem: any) =>
        elem && elem.attributes && elem.attributes.txn_type && elem.attributes.txn_type === 'payment_gateway')
    }
  }

  public showDrawer: boolean = false;
  public drawerHeading = '';
  public drawerContent = '';
  public closeDrawer() {
    this.showDrawer = false;
    this.drawerContent = '';
    if(this.cancellationResponse){
      location.reload();
    }
  }
  public openDrawer(type: string) {
    switch (type) {
      case 'cancelWarning':
        this.drawerHeading = 'Cancellation';
        this.drawerContent = type;
        this.showDrawer = true;
        break
      case 'cancelSuccess':
        this.drawerHeading = 'Cancellation Successful';
        this.drawerContent = type;
        this.showDrawer = true;
        break
      case 'cancelFailure':
        this.drawerHeading = 'Cancellation Failed';
        this.drawerContent = type;
        this.showDrawer = true;
        break
      case 'cancelReview':
        this.drawerHeading = 'Cancellation Review';
        this.drawerContent = type;
        this.showDrawer = true;
        break
    }

  }
}
