import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ICartItem } from "src/app/models/cart.interface";

@Injectable()
export class CartService {
  public cartItemsEmitter = new BehaviorSubject<ICartItem[]>([]);
  public redemptionPointsEmitter = new BehaviorSubject<number>(0);
  public cartItems = this.cartItemsEmitter.asObservable();
  public availableRedemptionPoints =
    this.redemptionPointsEmitter.asObservable();
  public areCartItemsLoaded: boolean = false;

  constructor(private http: HttpClient) {}

  public fetchCartItems(category: string = "GiftCard") {
    this.areCartItemsLoaded = false;
    this.http
      .get<any>(
        environment.apiUrlBsV1 + "carts/list_items?category=" + category
      )
      .toPromise()
      .then((res) => {
        this.areCartItemsLoaded = true;
        this.setCartItems(res?.data);
      })
      .catch((error) => throwError(error));
  }

  public addItemsToCart(payload: any) {
    return this.http
      .post<any>(environment.apiUrlBsV1 + "carts/add_items", payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }

  public patchCartItemQuantity(payload: any) {
    this.http
      .post<any>(environment.apiUrlBsV1 + "carts/add_items", payload)
      .toPromise()
      .then((res) => {
        this.setCartItems(res?.data);
      })
      .catch((error) => throwError(error));
  }

  public removeItemFromCart(payload: any) {
    this.http
      .post<any>(environment.apiUrlBsV1 + "carts/remove_item", payload)
      .toPromise()
      .then((res) => {
        this.setCartItems(res?.data);
      })
      .catch((error) => throwError(error));
  }

  public fetchRedemptionPoints() {
    let params = {
      fetch_points: true,
    };
    return this.http
      .get<any>(environment.corePathV2 + "user", {
        params: params,
      })
      .toPromise()
      .then((res) => {
        this.redemptionPointsEmitter.next(res?.points);
      })
      .catch((error) => throwError(error));
  }

  public setCartItems(cartItems: ICartItem[]) {
    this.cartItemsEmitter.next(cartItems);
  }

  public getTotalCartItemsValue(cartItems: ICartItem[]) {
    let sum = 0;
    let quantity = 0;
    cartItems?.map((cartItem: ICartItem) => {
      const value = cartItem?.attributes?.details?.discounted_value || cartItem?.attributes?.details?.denomination;
      sum +=
        value *
        cartItem?.attributes?.quantity;
      quantity += cartItem?.attributes?.quantity;
    });
    return {
      sum,
      quantity,
    };
  }
  public generateBookingId(payload: any) {
    return this.http
      .post<any>(environment.apiUrlBsV1 + "carts/checkout", payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  public removeFromFnpCart(payload: any) {
    return this.http
      .post<any>(environment.apiUrlBsV1 + "fnp/remove_order_item", payload)
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
  }
  public clearCart() {
    const payload = {
      category: "GiftCard",
    };
    this.http
      .post<any>(environment.apiUrlBsV1 + "carts/clear_cart", payload)
      .toPromise()
      .then((res) => {
        this.setCartItems([]);
      })
      .catch((error) => throwError(error));
  }
}
