import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { ApiService } from "src/app/core/services/api/api.service";
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable()
export class CommonService {

  public checkoutEmitter$ = new Subject<any>()
  
  constructor(
    private toastr: ToastrService,
    private _apiService: ApiService,
    private authService: AuthService
  ) { }

  public emitCheckoutEvent() {
    this.checkoutEmitter$.next();
  }

  public fetchUserDetailsAndCards(user: any) {
    this._apiService.getUserDetails().subscribe(
      (res: any) => {
        user['id'] = res.id;
        user['name'] = res.full_name;
        localStorage.setItem("userDetails", JSON.stringify(res));
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.authService.getCards().subscribe((response) => {
          if (response && response.data && response.data.length > 0) {
            let card = response.data[0];
            localStorage.setItem('card_verified', "true");
            localStorage.setItem('userCard', JSON.stringify(card));
            localStorage.setItem('userCards', JSON.stringify(response.data));
          }
        });
      },
      (err) => {
        this.clearAuthStorage();
      }
    );
  }

  public clearAuthStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  public copyContent(content: string,customMessage = '') {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = content;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    selBox.setSelectionRange(0,9000);
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(customMessage || 'Code copied to clipboard');
  }
}
