import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  private categories = new BehaviorSubject<any>({});
  private countries = new BehaviorSubject<any>({});
  private refreshEvent = new BehaviorSubject<any>(null);
  private componentReload = new BehaviorSubject<boolean>(false);
  sharedCategories = this.categories.asObservable();
  sharedCountries = this.countries.asObservable();;
  sharedRefreshEvent = this.refreshEvent.asObservable();


  constructor() { }

  nextCategories(categories:any) {
    this.categories.next(categories)
  }
  nextCountries(countries: any) {
    this.countries.next(countries)
  }

  nextRefreshEvent(refreshEvent: boolean) {
    this.refreshEvent.next(refreshEvent)
  }

}
