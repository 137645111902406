import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, map, refCount, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn:'root'
})
export class GiftingService{
    private memo:any = {};
    public genericEventEmitter = new EventEmitter<any>();
    constructor(private http: HttpClient) { }

    public getAllCategories() {
      if(!this.memo['fnp/get_filters']){
        return this.http
        .get<any>(environment.apiUrlBsV1 + "fnp/get_filters", {})
        .pipe(
          map((res) => res || []),
          catchError((error) => throwError(error)),
          shareReplay(1), // this tells Rx to cache the latest emitted
        );
      } else {
        return of(this.memo['fnp/get_filters']);
      }
      
    }

    public getAllGiftingOptions(){
      if(!this.memo['gift_card_listing']){
        return this.http
        .get<any>(environment.apiUrlBsV1 + "fnp/gift_card_listing", {})
        .pipe(
          map((res) =>
          {this.memo['gift_card_listing'] = res; return res;} ),
          catchError((error) => throwError(error)),
          shareReplay(1), // this tells Rx to cache the latest emitted
        );
      } else {
        return of(this.memo['gift_card_listing']);
      }
       
    }

    public getGiftingDetail(id:any){
      
      return this.http
        .get<any>(environment.apiUrlBsV1 + "fnp/gift_card_detail", {params:{id}})
        .pipe(
          map((res) => res || []),
          catchError((error) => throwError(error)),
          shareReplay(1), // this tells Rx to cache the latest emitted
        );
    }

    public getShippingDetails(id:string,pin_code:string){
      return this.http
      .get<any>(environment.apiUrlBsV1 + "fnp/get_individual_shipping_details",{params:{pin_code,id}})
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error)),
        shareReplay(1), // this tells Rx to cache the latest emitted
      );
    }

    public getPromoCodes(){
      return this.http
      .get<any>(environment.apiUrlBsV1 + "promo_codes",{})
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error)),
        shareReplay(1), // this tells Rx to cache the latest emitted
      );
    }
    public applyPromoCodes(booking_id:any,code:any){
      return this.http
      .get<any>(environment.apiUrlBsV1 + "promo_codes/apply",{params:{booking_id,code}})
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error)),
        shareReplay(1), // this tells Rx to cache the latest emitted
      );
    }
    public removePromoCodes(booking_id:any,code:any){
      return this.http
      .get<any>(environment.apiUrlBsV1 + "promo_codes/remove",{params:{booking_id,code}})
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error)),
        shareReplay(1), // this tells Rx to cache the latest emitted
      );
    }
    public getAllGiftingBookings(params:any){
      return this.http
      .get<any>(environment.apiUrlBsV1 + "bookings?type=FnpBooking",{params:params})
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
    }
    public getBookingById(booking_id:any){
      return this.http
      .get<any>(environment.apiUrlBsV1 + "bookings/"+booking_id,{params:{}})
      .pipe(
        map((res) => res || []),
        catchError((error) => throwError(error))
      );
    }

    
}