import { PaymentFailureComponent } from './pages/payments/payment-failure/payment-failure.component';
import { environment } from './../environments/environment.prod';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GiftingCheckoutComponent } from './pages/gifting/gifting-checkout/gifting-checkout.component';
import { HotelBookingDetailsComponent } from './pages/booking-history/hotel-details/hotel-details.component';
import { PaymentSuccessComponent } from './pages/payments/payment-success/payment-success.component';
import { PaymentStatusComponent } from './pages/payments/payment-status/payment-status.component';
import { AuthGuard } from './core/services/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule),
    // canActivate: [AuthGuard], // visit home only if authenticated
  },
  {
    path: 'sso_login',
    loadChildren: () => import("./pages/loginSso/loginSso.module").then(m => m.LoginSsoModule)
  },
  {
    path: 'flights',
    loadChildren: () => import("./pages/flights/flights.module").then(m => m.FlightsModule)
  },
  {
    path: 'hotels',
    loadChildren: () => import("./pages/hotels/hotels.module").then(m => m.HotelsModule)
  },
  {
    path: 'charitable-donations',
    loadChildren: () => import("./pages/charitable-giving/charitable-giving.module").then(m => m.CharitableGivingModule)
  },
  {
    path: 'charitable-donations/checkout-message',
    loadChildren: () => import("./pages/booking-history/booking-history.module").then(m => m.BookingHistoryModule)
  },
  {
    path: 'giftcards',
    loadChildren: () => import("./pages/giftcards/giftcards.module").then(m => m.GiftcardsModule)
  },
  {
    path: 'gifting',
    loadChildren: () => import("./pages/gifting/gifting.module").then(m => m.GiftingModule)
  },
  {
    path: 'memberships',
    loadChildren: () => import("./pages/memberships/memberships.module").then(m => m.MembershipsModule)
  },
  {
    path: 'payments',
    loadChildren: () => import("./pages/payments/payments.module").then(m => m.PaymentsModule)
  },
  {
    path: 'offers',
    loadChildren: () => import("./pages/offers-page/offers-page.module").then(m => m.OffersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "offers/:category_code",
    loadChildren: () => import("./pages/offers-page/offers-page.module").then(m => m.OffersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "offers/:category_code/:sub_cat_code",
    loadChildren: () => import("./pages/offers-page/offers-page.module").then(m => m.OffersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path:'offer-details',
    loadChildren: ()=> import("./pages/offer-details/offer-details.module").then(m=>m.OfferDetailsModule)
  },
  {
    path:'gifting/checkout-message/:booking_id',
    component: GiftingCheckoutComponent
  },
  {
    path:'hotels/checkout-message/:booking_id',
    component: HotelBookingDetailsComponent
  },
  {
    path:'hotels/booking-failed/:booking_id',
    component: HotelBookingDetailsComponent
  },
  {
    path: 'giftcards/checkout-message/:booking_id',
    component: PaymentStatusComponent
  },
  {
    path: 'memberships/checkout-message/:booking_id',
    component: PaymentStatusComponent
  },
  {
    path: 'charitable-donations/checkout-message/:booking_id',
    component: PaymentStatusComponent
  },
  {
    path: 'booking-failed',
    component: PaymentStatusComponent
  },
  {
    path:'offers/offer-details/',
    loadChildren: ()=> import("./pages/offer-details/offer-details.module").then(m=>m.OfferDetailsModule)
  },
  {
    path:'booking',
    loadChildren: ()=> import("./pages/redemption/redemption.module").then(m=>m.RedemptionModule)
  },
  {
    path:'offers/offer-details/:merchantId',
    loadChildren: ()=> import("./pages/offer-details/offer-details.module").then(m=>m.OfferDetailsModule)
  },
  // {
  //   path:'offers/offer-details/:merchantId/:categoryType',
  //   loadChildren: ()=> import("./pages/offer-details/offer-details.module").then(m=>m.OfferDetailsModule)
  // },
  // {
  //   path:'offer-details/:merchantId/:categoryType/:merhcantName',
  //   loadChildren: ()=> import("./pages/offer-details/offer-details.module").then(m=>m.OfferDetailsModule)
  // },
  // {
  //   path:'offer/offer-details/:merchantId/:categoryType/:merhcantName',
  //   loadChildren: ()=> import("./pages/offer-details/offer-details.module").then(m=>m.OfferDetailsModule)
  // },
  {
    path: "history",
    loadChildren: () =>
      import("./pages/booking-history/booking-history.module").then(
        m => m.BookingHistoryModule
      )
  },
  {
    path: "info",
    loadChildren: () =>
      import("./pages/info/info.module").then(
        m => m.InfoModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
