import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

import { HeaderComponent } from '../core/components/header/header.component';
import { FooterComponent } from '../core/components/footer/footer.component';

import { SafeHtml } from '../core/services/pipes/safe-svg.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,


    SafeHtml
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
 

    SafeHtml,
    //modules

    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    ModalModule
  ]
})
export class CoreModule { }
