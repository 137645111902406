<app-back [text]="'Order Confirmed'" [link]="false" [isModal]="true" (backButtonClickEmitter)="handleBackEmitter($event)"></app-back>
<ng-container *ngIf="!loading">
    <div class="checkout-container" *ngIf="bookingDetail">
        <div *ngIf="bookingDetail?.attributes?.status === 'booking_failed'" class="success-message-box flex" style="padding-bottom:12px;">
            <div class="failed-icon flex align-center justify-center">
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.29438 7.65306C5.29438 7.81544 5.36132 7.97117 5.48046 8.08598C5.59959 8.2008 5.76118 8.26531 5.92967 8.26531C6.09816 8.26531 6.25975 8.2008 6.37888 8.08598C6.49802 7.97117 6.56496 7.81544 6.56496 7.65306C6.56496 7.49068 6.49802 7.33496 6.37888 7.22014C6.25975 7.10532 6.09816 7.04082 5.92967 7.04082C5.76118 7.04082 5.59959 7.10532 5.48046 7.22014C5.36132 7.33496 5.29438 7.49068 5.29438 7.65306ZM5.50615 3.77551V6.12245C5.50615 6.17857 5.55379 6.22449 5.61203 6.22449H6.24731C6.30555 6.22449 6.35319 6.17857 6.35319 6.12245V3.77551C6.35319 3.71939 6.30555 3.67347 6.24731 3.67347H5.61203C5.55379 3.67347 5.50615 3.71939 5.50615 3.77551ZM11.8021 9.38776L6.29628 0.204082C6.21423 0.067602 6.07261 0 5.92967 0C5.78673 0 5.64379 0.067602 5.56306 0.204082L0.0572464 9.38776C-0.105546 9.66071 0.0982752 10 0.423859 10H11.4355C11.7611 10 11.9649 9.66071 11.8021 9.38776ZM1.43238 9.03189L5.92967 1.52934L10.427 9.03189H1.43238Z"
                        fill="white" />
                </svg>
            </div>
            <div class="failed-info pad-left-12">
                <div class="f700 fnt-16">Oops! your booking is not confirmed</div>
                <div class="fnt-12 mar-top-8">Please try again later</div>
            </div>
        </div>
        <div  class="success-message-box" *ngIf="bookingDetail?.attributes?.status !== 'booking_failed'">
            <div class="first-section">
                <img src="../../../../assets/success-tick.svg">
                <span class="mess">
                    Your order has been placed successfully!
                </span>
            </div>
            <div class="content">
                Please check your email and mobile number for order confirmation and detailed delivery information.
            </div>
            <div class="booking">
                Booking ID : 
                <span>&nbsp;&nbsp;{{bookingDetail?.attributes?.unique_id}}</span>
            </div>
        </div>
        <ng-container *ngFor="let item of bookingDetail?.attributes?.details?.attributes?.fnp_orders">
            <div class="d-flex flex-column cart-container">
                <div class="merchant-detail">
                    <!-- <img class="merchant-logo" [src]="item?.attributes?.image_url || 'assets/image-coming-soon.png'" [def]> -->
                    <img class="merchant-logo" [defaultImage]="'https://images.poshvine.com/b/default-thumbnail-square.jpg'"
                    [lazyLoad]="item?.attributes?.image_url || 'https://images.poshvine.com/b/default-thumbnail-square.jpg' ">
                    <div class="item-details f700">
                        <span class="merchant-title ellipsis mar-bot-4">{{item?.attributes?.name}}</span>
                        <span>₹ {{item?.attributes?.value | indianCurrency}}</span>
                    </div>
                </div>
                <div class="delivery-details">
                    <div class="head">Delivery Details</div>
                    <div class="item">
                        <div class="h">Recepients Name </div>
                        <span class="c">
                            {{item?.attributes?.recipient?.first_name}}&nbsp;{{item?.attributes?.recipient?.last_name}}
                        </span>
                    </div>
                    <div class="item">
                        <div class="h">Delivery Date & Time</div>
                        <span class="c">
                            {{item?.attributes?.deliver_at|date}}&nbsp;{{item?.attributes?.deliver_slot}}
                        </span>
                    </div>
                    <div class="item">
                        <div class="h">Delivery Address</div>
                        <span class="c">
                            {{item?.attributes?.address?.street}},&nbsp;{{item?.attributes?.address?.pincode}},&nbsp;{{item?.attributes?.address?.city}}
                        </span>
                    </div>
                    <div class="item">
                        <div class="h">Gift Message</div>
                        <span class="c">
                            {{item?.attributes?.gift_message || 'N.A.'}}
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
       
        <div class="payment-detail">
            <span class="fnt-16 f700 mar-bot-20">Payment Details</span>
            <div class="d-flex align-items-center justify-content-between mar-bot-15">
                <span>Base Fare</span>
                <span class="f700">₹ {{bookingDetail?.attributes?.details?.attributes?.fare_breakup?.base | indianCurrency}}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mar-bot-20">
                <span>Tax</span>
                <span class="f700">₹ {{bookingDetail?.attributes?.details?.attributes?.fare_breakup?.taxes | indianCurrency}}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mar-bot-20">
                <span>Shipping</span>
                <span class="f700">₹ {{bookingDetail?.attributes?.details?.attributes?.fare_breakup?.shipping_fee | indianCurrency}}</span>
            </div>
            <span class="separator"></span>
            <div class="d-flex align-items-center justify-content-between f700 fnt-16 mar-top-5">
                <span>Total(₹)</span>
                <span>₹ {{bookingDetail?.attributes?.details?.attributes?.fare_breakup?.price | indianCurrency}}</span>
            </div>
        </div>
        <div class="payment-detail" style="">
            <div class="d-flex align-items-center justify-content-between">
                <span>Payable by Gems</span>
                <span class="f700">₹ {{bookingDetail?.attributes?.booking_details?.payment_details?.points_transaction?.amount | indianCurrency}}</span>
            </div>
        </div>
        <!-- <div class="payment-detail" style="">
            <div class="d-flex align-items-center justify-content-between">
                <span>Payable by points</span>
                <span class="f700">₹ {{bookingDetail?.attributes?.booking_details?.payment_details?.points_transaction?.amount | indianCurrency}}</span>
            </div>
        </div> -->
        <div class="paid-by-card" *ngIf="bookingDetail?.attributes?.booking_details?.payment_details?.card_transaction">
            <div class="header">
                Amount paid by card
            </div>
            <div class="fare-item">
                <span class="key card-val">{{bookingDetail?.attributes?.booking_details?.payment_details?.card_transaction?.cardnum}}</span>
                <span class="value">₹ {{(bookingDetail?.attributes?.booking_details?.payment_details?.card_transaction?.amount)? 
                    bookingDetail?.attributes?.booking_details?.payment_details?.card_transaction?.amount : 0 | indianCurrency}}</span>
            </div>
        </div>
    
    </div>
    <app-checkout-footer [showQuantity]="false" [totalCartValue]="totalCartValue" [templateNumber]="1"
        [btnTitle]="'Back to home'" (buttonClickEvent)="handleBackEmitter($event)"></app-checkout-footer>
</ng-container>
<ng-container *ngIf="loading">
    <div class="" style="padding: 20px;">
        <app-skeleton-loader *ngFor="let elem of [1,2,3,4,5,6]" [type]="'cartCards'"></app-skeleton-loader>    
    </div>
</ng-container>