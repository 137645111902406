import { Component, OnInit, Input } from '@angular/core';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.scss']
})
export class StatusLabelComponent implements OnInit {
  @Input('status') status:any;
  @Input() date:any;
  statemapper:any = {
    booking_initiated : 'confirmed',
    booking_confirmed : 'confirmed',
    booking_completed : 'confirmed',
    booking_pending : 'upcoming',
    cancellation_requested : 'cancellation_requested',
    booking_cancelled : 'cancelled',
    booking_failed : 'cancelled',
    payment_link_sent : 'payment_link_sent',
    // transaction_failed = 'cancelled' , 
    // transaction_initiated = 'upcoming' ,
    booking_in_progress : 'booking_in_progress',
    refunded : "cancelled",
    refund_initiated : "upcoming",
    upcoming : "upcoming",
    enquired : "confirmed",
    availed : "confirmed",
    booking_partial_confirmed:"booking_in_progress"
  }
  offerCardMapper:any = {
    partial_success: 'partially_succesfull',
    success:'successful',
    booking_confirmed:'successful',
    booking_created:'booking_created',
    booking_failed:'failed',
    failure:'failed',
    closed:'failed',
    initiated:'booking_initiated',
    booking_cancelled:'cancelled',
    pending:'pending',
    booking_pending:'pending',
    booking_in_progress:'pending'
  }
  @Input() type = 'generic';
  currentStatus:any;
  constructor() { }

  ngOnInit() {
    if(this.type === 'generic'){
      this.currentStatus = this.statemapper[this.status];
    } else {
    this.currentStatus = this.offerCardMapper[this.status];

    }

  }

  removeBooking(status:any) {
    if (status == 'booking_in_progress') {
      return 'Initiated';
    } else if (status == 'cancellation_requested') {
      if (window.innerWidth < 576)
        return 'Cancellation Requested'
      else {
        return 'Cancellation Requested'
      }
    }
    return this.changeStatus().replace('booking_', '');
  }


  changeStatus() {
    if (this.date) {
      if (this.checkIfUpcoming() && this.status === 'booking_confirmed') {
        return 'upcoming';
      } else if (this.checkIfCompleted() && this.status === 'booking_confirmed') {
        return 'booking_completed';
      }
    }
    return this.status;
  }

  checkIfCompleted() {
    return moment(this.date).isBefore(moment())
  }

  checkIfUpcoming() {
    return moment(this.date).isAfter(moment())
  }
}
