import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { tap } from "rxjs/operators";
// import { Router } from "@angular/router";
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private _authService: AuthService,

  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      setHeaders: {
        'X-Client-Id': environment.clientId,
        'X-Project-Id': environment.projectId,
        // 'x-client-id': 'b191c165-ffeb-4d55-81b9-6278b3c08bc5',
        // 'x-project-id': '5c614407-968f-4e8f-8ff5-bbef5d4222b6',
        // 'x-user-card-id': '290fb2ba-820e-4ede-8d1a-0e048e8ed4b6'
      }
    });
    if(request.headers.get('X-Country-Id')){

    }
    // else if(localStorage.getItem("userCountry")){
    //   let userCountry = JSON.parse(localStorage.getItem('userCountry'));
    //     if(userCountry){
    //       request = request.clone({
    //         setHeaders: {
    //           'X-Country-Id': userCountry.id ? userCountry.id : userCountry.attributes.id
    //         }
    //       });
    //     }
    // }
    var currentUser;
    if (localStorage.getItem('currentUser')) {
      currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
      if (currentUser.token) {
        request = request.clone({
          setHeaders: {
            'Authorization': "Bearer " + `${currentUser.token}`
          }
        });
      }
    }
    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          // console.log(response);
        },
        (error: HttpErrorResponse) => {
          if (error["status"] == 401){
            this._authService.logout();

            
          }
          else if (error["status"] != 200 && error["status"] != 401 && error["status"] != 404) {
            // this.router.navigate(["error"]);
          }
        }
      )
    );
  }

}
