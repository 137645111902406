import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { hotelType } from './hotel.constants';
@Injectable({
  providedIn: 'root',
})
export class HotelsApiService {
  
  protected flightPaymentDetails = {};

  constructor(private http: HttpClient,) {}

  getNationalities() {
    return this.http.get(environment.apiUrlBsV1 + '/country_details/get_nationalities')
      .toPromise();
  }

  getCountries() {
    return this.http.get(environment.apiUrlBsV1 + '/country_details/get_countries')
      .toPromise();
  }



  
  getBookingsById(id:string) {
    return this.http.get(environment.apiUrlBsV1 + `bookings/${id}`)
      .toPromise();
  }

  getAutocompleteResult(queryString: any, hotelType: boolean) {
    let params = new HttpParams();
    if (queryString) {
      params = params.append('search', queryString);
    }
    if (hotelType) {
      return this.http.get(environment.apiUrlBsV1 + "luxury_staycations/autocomplete", { params: params })
    } else {
      return this.http.get(environment.apiUrlBsV1 + "hotels/autocomplete", { params: params })
    }
  }
  getHotelListingPriceAvailability(destinationId:string, checkInOutStartDate:string, checkInOutEndDate:string, rooms:any) {
    let url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_availability";
    if (parseInt(localStorage.getItem('hotelType') || '{}') === hotelType.luxurystaycation) {
      url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_availability";
      if (localStorage.getItem('isCountry') === 'yes') {
        let body = {
          rooms: rooms, //[{"adults": 2, "children": 1}],
          check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
          check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
          country_id: destinationId
        }
        return this.http.post(url, body)
      } else {
        let body = {
          rooms: rooms, //[{"adults": 2, "children": 1}],
          check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
          check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
          destination_id: destinationId
        }
        return this.http.post(url, body)
      }
    } else {
      url = environment.apiUrlBsV1 + "/hotels/hotel_availability";
      if (localStorage.getItem('isLocality') === 'yes') {
        let body = {
          rooms: rooms, //[{"adults": 2, "children": 1}],
          check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
          check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
          locality_id: destinationId
        }
        return this.http.post(url, body)
      } else {
        let body = {
          rooms: rooms, //[{"adults": 2, "children": 1}],
          check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
          check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
          destination_id: destinationId
        }
        return this.http.post(url, body)
      }
    }
  }
  getHotelListDetails(destinationId:string) {
    let url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_list_details?destination_id=" + destinationId;
    
    if (parseInt(localStorage.getItem('hotelType') || '{}') === hotelType.luxurystaycation) {
      if (localStorage.getItem('isCountry') === 'yes') {
        url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_list_details?country_id=" + destinationId;
      } else {
        url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_list_details?destination_id=" + destinationId;
      }
    } else {
      if (localStorage.getItem('isLocality') === 'yes') {
        url = environment.apiUrlBsV1 + "/hotels/hotel_list_details?locality_id=" + destinationId;
      } else {
        url = environment.apiUrlBsV1 + "/hotels/hotel_list_details?destination_id=" + destinationId;
      }
    }
    return this.http.get(url)
  }

  getHotelDetails(hotelId: any){
    let url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_details?hotel_id="+hotelId;
    if(parseInt(localStorage.getItem('hotelType') as any) === hotelType.luxurystaycation){
      url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_details?hotel_id="+hotelId;
    }else{
      url = environment.apiUrlBsV1 + "/hotels/hotel_details?hotel_id="+hotelId;
    }
    return this.http.get(url)
  }

  getHotelRoomDetails(hotelId : any){
    let url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_room_details?hotel_id="+hotelId;
    if(parseInt(localStorage.getItem('hotelType') as any) === hotelType.luxurystaycation){
      url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_room_details?hotel_id="+hotelId;
    }else{
      url = environment.apiUrlBsV1 + "/hotels/hotel_room_details?hotel_id="+hotelId;
    }
    return this.http.get(url)
  }

  getHotelRoomsAvailability(hotelId: any, checkInOutStartDate: any, checkInOutEndDate: any, rooms: any){
    let url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_room_availability";
    if(parseInt(localStorage.getItem('hotelType') as any) === hotelType.luxurystaycation){
      url = environment.apiUrlBsV1 + "/luxury_staycations/hotel_room_availability";
    }else{
      url = environment.apiUrlBsV1 + "/hotels/hotel_room_availability";
    }
    let body = {
      rooms: rooms, //[{"adults": 2, "children": 1}],
      check_in: checkInOutStartDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
      check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
      hotel_id: hotelId
    }
    return this.http.post(url,body)
  } 

  createBooking(initiationDetails: any){
    let url = environment.apiUrlBsV1 + "/luxury_staycations/create_booking";
    let body = {};
    if(parseInt(localStorage.getItem('hotelType') as any) === hotelType.luxurystaycation){
      url = environment.apiUrlBsV1 + "/luxury_staycations/create_booking";
      body = {
        rooms: initiationDetails.rooms, //[{"adults": 2, "children": 1}],
        hotel_id: initiationDetails.hotelId,
        check_in: initiationDetails.checkInDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
        check_out: initiationDetails.checkOutDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
        "hotel_currency": localStorage.getItem('hotel_currency'),
        // first_name: 'TEST',//+ initiationDetails.travellerDetailForm.first_name.replace(/^\s+|\s+$/g, ''),
        // last_name: 'TEST',// + initiationDetails.travellerDetailForm.last_name.replace(/^\s+|\s+$/g, ''),
        // customer_country: "IN",
        // email: initiationDetails.travellerDetailForm.email,
        // mobile_no : initiationDetails.travellerDetailForm.mobile
      }
    }else{
      url = environment.apiUrlBsV1 + "/hotels/create_booking";
      body = {
        rooms: initiationDetails.rooms, //[{"adults": 2, "children": 1}],
        hotel_id: initiationDetails.hotelId,
        check_in: initiationDetails.checkInDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
        check_out: initiationDetails.checkOutDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
        "hotel_currency": "INR",
        // first_name: initiationDetails.travellerDetailForm.primaryTravellerDetails[0].first_name.replace(/^\s+|\s+$/g, ''),
        // last_name: initiationDetails.travellerDetailForm.primaryTravellerDetails[0].last_name.replace(/^\s+|\s+$/g, ''),
        // title : initiationDetails.travellerDetailForm.primaryTravellerDetails[0].title.replace(/^\s+|\s+$/g, ''),
        // mobile_no : initiationDetails.travellerDetailForm.mobile,
        // email: initiationDetails.travellerDetailForm.email,
        total_price : initiationDetails.total_price,
        // provider : initiationDetails.provider
      }
    }
    return this.http.post(url,body)
  }

  initiateBooking(initiationDetails: any){
    let url = environment.apiUrlBsV1 + "/luxury_staycations/initiate_booking";
    console.log('id', initiationDetails)
    let body = {};
    if(parseInt(localStorage.getItem('hotelType') as any) === hotelType.luxurystaycation){
      url = environment.apiUrlBsV1 + "/luxury_staycations/initiate_booking";
      body = {
        // rooms: initiationDetails.rooms, //[{"adults": 2, "children": 1}],
        hotel_id: initiationDetails.hotelId,
        check_in: initiationDetails.checkInDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
        check_out: initiationDetails.checkOutDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
        "hotel_currency": localStorage.getItem('hotel_currency'),
        first_name: environment.production  ? initiationDetails.travellerDetailForm.first_name.replace(/^\s+|\s+$/g, '') : 'TEST',//+ initiationDetails.travellerDetailForm.first_name.replace(/^\s+|\s+$/g, ''),
        last_name: environment.production  ? initiationDetails.travellerDetailForm.last_name.replace(/^\s+|\s+$/g, '') : 'TEST',// + initiationDetails.travellerDetailForm.last_name.replace(/^\s+|\s+$/g, ''),
        customer_country: "IN",
        email: initiationDetails.travellerDetailForm.email,
        mobile_no : initiationDetails.travellerDetailForm.mobile,
        booking_id : initiationDetails.bookingId
      }
    }else{
      url = environment.apiUrlBsV1 + "/hotels/initiate_booking";
      body = {
        rooms: initiationDetails.rooms, //[{"adults": 2, "children": 1}],
        // hotel_id: initiationDetails.hotelId,
        // check_in: initiationDetails.checkInDate,// checkInOutDate.startDate.format('YYYY-MM-DD'),
        // check_out: initiationDetails.checkOutDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
        // "hotel_currency": "INR",
        first_name: initiationDetails.travellerDetailForm.first_name.replace(/^\s+|\s+$/g, ''),
        last_name: initiationDetails.travellerDetailForm.last_name.replace(/^\s+|\s+$/g, ''),
        title : initiationDetails.travellerDetailForm?.title?.replace(/^\s+|\s+$/g, ''),
        mobile_no : initiationDetails.travellerDetailForm.mobile,
        email: initiationDetails.travellerDetailForm.email,
        total_price : initiationDetails.total_price,
        provider : initiationDetails.provider,
        booking_id : initiationDetails.bookingId
      }
    }
    return this.http.post(url,body)
  }

  async getCurrencyExchangeRates() {
    let url = environment.apiUrlBsV1 + "/exchange_rates";
    const api = await this.http.get(url).toPromise();
    return api;
  }

  convertGbptoInr() {
    return this.getCurrencyExchangeRates()
  }
  checkCancellationCharges(bookingId: string) {
    let url = environment.apiUrlBsV1 + '/hotels/cancellation_charges?booking_id=' + bookingId;
    return this.http.get(url)
  }

  cancelHotelBooking(bookingId: string) {
    let url = environment.apiUrlBsV1 + '/hotels/cancel_booking';
    let body = {
      "booking_id": bookingId
    }
    return this.http.post(url, body)
  }
}
