import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private login = new BehaviorSubject(true);
  login$ = this.login.asObservable();

  private changeCard = new BehaviorSubject(true);
  changeCard$ = this.changeCard.asObservable();
  apiUrlBsV1 = environment.apiUrlBsV1;
  modalRef!: BsModalRef;
  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
  ) { }



  emitLogin(data: any) {
    this.login.next(data)
  }

  changeCardEmit(data: any) {
    this.changeCard.next(data)
  }



}
