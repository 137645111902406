export const environment = {
  production: true,
  sentryDsn:"https://fe9a63db785cc23ea3588240d28ffd09@o515678.ingest.us.sentry.io/4507412756496384",
  SENTRY_ORG:"rzp",
  SENTRY_PROJECT:"indie",
  SENTRY_AUTH_TOKEN:"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",
  corePathV2: "https://api.poshvine.com/cs/v1/",
  giftPathV2: "https://offers-api.poshvine.com/offer_service/v2",
  offerPathV2: "https://api.poshvine.com/ps/v1/",
  apiUrlBsV1: "https://api.poshvine.com/bs/v1/",
  apiUrlPaV1: "https://api.poshvine.com/pa/v1/",
  apiUrlLsV1: "https://api.poshvine.com/ls/v1/",
  baseUrl: "https://api.poshvine.com/",
  stagingUrl: 'https://rewardshub-stage-api.poshvine.com/',
  secretKey: '000102030405060708090a0b0c0d0e0f',
  ipstackKey: '022375a36968cebce87e10e1c6447ef6',
  clientId: '4f7fac11-1f31-46b9-876f-79ec62f886ed',
  projectId: 'b56409a7-5662-4523-abdd-d55f76a46c53',
  gmApiKey: 'AIzaSyAXREnbpzSGulf8xS5J43tsYwrbBFltR7k',//'AIzaSyC2AtPyxZua0AcvWyoPZi-M_WuXoOo_gRc'
}
