import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  PaymentService,
} from "src/app/store/services/payment.service";

@Component({
  selector: "app-payment-status",
  templateUrl: "./payment-status.component.html",
  styleUrls: ["./payment-status.component.scss"],
})
export class PaymentStatusComponent implements OnInit {
  public showLoader = true;
  public bookingStatus!:string;
  public bookingData?: any;
  public bookingType?: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.getBookingStatus();
  }

  public navigateTo(routeString: string) {
    this.router.navigate([routeString])
  }

  private getBookingStatus(){
    this.activatedRoute.params.subscribe(
      params => {
        if (params.booking_id) {
          this.fetchBookingData(params.booking_id);
        } else {
          if (this.activatedRoute.snapshot.queryParams.booking_id) {
            this.fetchBookingData(this.activatedRoute.snapshot.queryParams.booking_id);
          } else {
            this.bookingStatus = 'failure'
          }
        }
      });
  }

  private fetchBookingData(bookingId: string) {
    this._paymentService.getBookingDetails(bookingId).subscribe((res:any) => {
      const bookingType = {
        GiftCardBooking : "Gift card",
        MembershipBooking : "Membership",
      }
      this.showLoader = false;
      this.bookingData = res?.data;
      this.bookingType = bookingType[res?.data?.attributes?.owner_booking_type as keyof {
        GiftCardBooking: string;
        MembershipBooking: string;
    }]
      if (res?.data?.attributes?.status === 'booking_confirmed' || res?.data?.attributes?.status === 'booking_partial_confirmed'){
        this.bookingStatus = 'success'
      }
      else if (res?.data?.attributes?.status === 'booking_failed') {
        this.bookingStatus = 'failure'
      }
      else{
        this.bookingStatus = 'initiated'
      }
    });
  }
  
}
