import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.scss']
})
export class PaymentFailureComponent implements OnInit, OnDestroy {

  @Input() bookingStatus: string = 'failure';
  @Input() bookingData: any;
  @Input() public bookingType?: string;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private routedCat:any = '';
  protected showContent:any = false;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap
    .subscribe((params:any) => {
      if(params?.params?.category){
        this.routedCat = params?.params?.category;
        this.createRoute(params?.params?.category,params?.params?.booking_id);
      }
      this.showContent = true;
    });
  }

  public navigateTo(routeString: string) {
    if (routeString === 'app'){
      (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({status:"EXIT_WEB-VIEW"}));
    } else if(this.routedCat){
      this.router.navigate([this.routedCat]);
    } else {
      if (this.bookingType) {
        const navigationExtras =  {
          preserveFragment: true,
          queryParams: {
            app: this.bookingType === 'Gift card' ? 'Gift Cards' : `${this.bookingType}s`
          }
        }
        this.router.navigate([routeString], navigationExtras)
      } else {
        this.router.navigate([routeString]);
      }
    }
  }

  createRoute(category:any,booking_id:any){
    try {
      const mapper:{[x:string]:string} = {
        flights:'flights/checkout-message/',
        gifting:'gifting/checkout-message/',
        hotels:'hotels/checkout-message/',//booking-failed?booking_id=f90a539d-ffb5-47e5-ac78-b367dd959af7&category=hotels
        // 'charitable-donations':'TEST' //booking-failed?booking_id=0e683f94-0054-4d2c-8ecc-c71bc8db78ef&category=charitable-donations
        // "memberships":'test' //booking-failed?booking_id=92a6e4bf-8144-406f-9d60-c68ac5793b10&category=memberships
        
      }
      if(mapper[category]){
        console.log(mapper[category]+booking_id,'navigate url')
        this.router.navigateByUrl(mapper[category]+booking_id);
      } else {
        console.log('category',this.routedCat);
      }
    } catch (error) {
      this.showContent = true;
    }
    
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
