import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
// declare global {
//   interface Window { dataLayer: any[]; smartech: any }
// }
@Component({
  selector: "app-back",
  templateUrl: "./back.component.html",
  styleUrls: ["./back.component.scss"],
})
export class BackComponent implements OnInit {
  
  @Input() bgColor = '#fff';
  @Input() link = '/';
  @Input() text = '/';
  @Input() isModal: boolean = false;
  @Input() preserveQueryParam: boolean = false;
  @Input() queryParams: any;

  @Output() backButtonClickEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    
  }
  back() {
    if (this.isModal) {
      this.backButtonClickEmitter.emit();
      return;
    }
    else if (!this.link){
      history.back();
    }
    else if (this.link === 'app'){
      (window as any)?.ReactNativeWebView?.postMessage(JSON.stringify({status:"EXIT_WEB-VIEW"}));
    }
    else if (this.link === 'custom'){

    }
    else{
      let navigationExtras = {};
      if (this.preserveQueryParam) {
        navigationExtras =  {
          queryParamsHandling: 'merge',
          preserveFragment: true,
          queryParams: this.queryParams
        }
      }
      this.router.navigate([this.link], navigationExtras);
    }
  }
} 
