import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
	constructor(
		private http: HttpClient,
    private router: Router,
    private _apiService: ApiService
	) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
	}
	reloadTemplate = new EventEmitter<any>();

  getUser(userId: string) {
		return this.http.get<any>(environment.corePathV2 + 'users/' + userId, {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}
	getUserDetails() {
		return this.http.get<any>(environment.corePathV2 + 'user', {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}

  updateUserCreds(params: any) {
    return this.http.put(environment.corePathV2 + "/user", params).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }
	getCards(params = {}) {
		return this.http.get<any>(environment.corePathV2 + 'user/user_cards', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
	}



  binValidation(bin: string) {
		return this.http.get<any>(environment.offerPathV2 + "/bins/card_details?bin=" + bin).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}
  cardValidation(bin: string) {
		return this.http.post<any>(environment.corePathV2 + "bins/validate", {bin: bin}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}
  addCard(bin: string, bin_type: string ){
		return this.http.post<any>(environment.corePathV2 + "user/user_cards", {bin: bin, bin_type : bin_type }).pipe(
		  map(res => res || []),
		  catchError(error => throwError(error))
		);
	}
	validateCard(bin: string, bin_type = "first8") {
		return this.http.post<any>(environment.corePathV2 + "user/user_cards/search",{bin:bin, bin_type:bin_type}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}







  addLocation(params: any) {
    return this.http.post<any>(environment.corePathV2 + "user/user_locations/log", params).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }



  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  isAuthenticated() {
      return localStorage.getItem('currentUser') ? true : false;
  }
  addUserCard(bin: string) {
    return this.http.post(environment.corePathV2 + 'user/user_cards', { bin: bin, bin_type: 'first8' });
  }

  deleteToken() {
    if (localStorage.getItem('login_details')) {
      const currentUserId: Storage = JSON.parse(localStorage.getItem('login_details') || '{}').id;
      return this.http.post(environment.corePathV2 + 'sessions/' + currentUserId, {}).toPromise();
    }
  }

}
