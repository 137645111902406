<section class="drawer w100" [ngClass]="{'pad-left-20 pad-right-20' : addPadding}" [class.open] = "open" 
[ngStyle]="{'height': height}" [class.pad-top-25] = "!showTopbar">
   <div class="flex space-between align-center sticky pad-top-25" *ngIf = "showTopbar">
      <h3 *ngIf="headerType === 'custom'" class="fnt-16 f600">{{heading}}</h3>
      <h3 *ngIf="headerType === 'generic'" class="fnt-18 f700 m-0">{{heading}}</h3>
      <span (click) = "close()">
          <img src = "https://images.poshvine.com/icon/indie/close-icon.svg">
      </span>
  </div>
   <ng-content></ng-content>
</section>
<div class="overlay" *ngIf = "open" (click) = "close()"></div>