<div class="footer-container">
  <ng-container *ngIf="templateNumber === 1">
    <div *ngIf="showQuantity" class="w-50 f700 d-flex flex-column">
      <span>{{ totalCartValue?.quantity }} items: ₹
        {{ totalCartValue?.sum | indianCurrency }}</span>
      <ng-container *ngIf="totalCartValue?.discount">
        <span class="discount-tag">Total Discount: ₹
          {{ totalCartValue?.discount | indianCurrency }}</span>
        <span>Total Payable: ₹
          {{
          totalCartValue?.sum - (totalCartValue?.discount | indianCurrency)
          }}</span>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="templateNumber === 2">
    <div class="d-flex flex-column w-50">
      <span class="fnt-12">Amount Payable:</span>
            <span class="fnt-16 f700">₹ {{totalCartValue?.sum | indianCurrency}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="templateNumber === 3">
    <a [routerLink]="routerLinkUrl" class="w-50 text-primary">Continue Shopping</a>
  </ng-container>
    <button [disabled]="isLoading" class="btn btn-primary btn-sm btn-pill" [ngClass]="showQuantity ? 'w-50' : 'w-100'" (click)="emitEvent()">
        {{btnTitle}}
    <app-skeleton-loader *ngIf="isLoading" [type]="'button'"></app-skeleton-loader>
  </button>
</div>