import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'indianCurrency'})
export class IndianCurrency implements PipeTransform {
  transform(value: number, defaultEmptyValue?:boolean|null): any {
    // console.log("result"+value)
        if (! isNaN(value)) {
            var currencySymbol = '₹';
            if(!value && defaultEmptyValue) {
                return value;
            }
            if(!value){
                return '0';
            }          
            var result = value.toString().split('.');

            var lastThree = result[0].substring(result[0].length - 3);
            var otherNumbers = result[0].substring(0, result[0].length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            
            output = output.replace(/\s/g, '');
            return output;
        }

  }
}