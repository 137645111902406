import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/app/store/services/common.service';

@Component({
  selector: 'app-checkout-footer',
  templateUrl: './checkout-footer.component.html',
  styleUrls: ['./checkout-footer.component.scss']
})
export class CheckoutFooterComponent implements OnInit {

  @Input() public showQuantity: boolean = true
  @Input() public totalCartValue: any = {
    quantity: 0,
    sum: 0
  };
  @Input() public templateNumber: number = 1;
  @Input() public btnTitle: string = '';
  @Input() public routerLinkUrl: string = '/giftcards';
  @Input() public isLoading: boolean = false;
  @Output() private buttonClickEvent:EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  public emitEvent(): void {
    this.buttonClickEvent.emit(this.btnTitle);
    this.commonService.emitCheckoutEvent();
  }
}
